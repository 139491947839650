import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsCanceledAssayPipe } from './is-canceled-assay.pipe';

@NgModule({
  declarations: [IsCanceledAssayPipe],
  exports: [IsCanceledAssayPipe],
  imports: [CommonModule],
})
export class SharedModule {}
