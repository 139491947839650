<div data-cy="toolbar" class="toolbar" luxLayout="row" luxLayoutGap="13px">
  <app-run-picker
    #runPicker
    (handleNextInQueueClick)="nextInQueue()"
    [lab]="lab"
    [runs]="runs"
    [disabled]="hasChanges"
    [currentRun]="currentRun"></app-run-picker>
  <app-repeat-run #repeatRun [run]="runs | currentRun: currentRun" [hasChanges]="hasChanges"></app-repeat-run>
  <app-manual-diff
    #manualDiff
    [accessionAssays]="accession?.assays"
    [disabled]="
      hasChanges || (!accession?._links?.addManualDiff && !accession?._links?.manualDiffCount)
    "></app-manual-diff>
  <div data-cy="user-feedback" id="user-feedback">
    <div data-cy="unsaved-changes-message" class="spot-form__microcopy" *ngIf="hasChanges">
      {{ 'ERRORS_AND_FEEDBACK.MUST_SAVE_BEFORE_ACTION' | translate }}
    </div>
  </div>
</div>
