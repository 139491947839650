import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuditService, Link } from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

import { Observable, of, catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs';

import { AppStateService } from '../app-state.service';
import { AccessionService } from './accession/accession.service';
import { CBCAccession, WorkspaceAccessionService } from './workspace-accession.service';
import { Router } from '@angular/router';

interface QueueLinks {
  _links: {
    next: Link;
    self: Link;
  };
}

@Injectable({
  providedIn: 'root',
})
export class WorkspaceQueueService {
  constructor(
    public appStateService: AppStateService,
    private assayService: AccessionService,
    private workspaceService: WorkspaceAccessionService,
    private http: HttpClient,
    private auditService: AuditService,
    private router: Router
  ) {}

  advanceQueue(workspaceQueueUrl: Link): Observable<CBCAccession | null> {
    return this.http.get<QueueLinks>(workspaceQueueUrl.href).pipe(
      catchError((err) => {
        return of(err);
      }),
      map((workspaceQueue) => {
        if (workspaceQueue?._links?.next) {
          const workspaceQueueNextUrl = workspaceQueue._links.next;
          this.appStateService.workspaceQueueNextUrl = workspaceQueueNextUrl;
          this.appStateService.queueWorkspace = true;
          return workspaceQueue;
        } else {
          this.appStateService.workspaceQueueNextUrl = null;
          this.appStateService.queueWorkspace = false;
          this.router.navigate([
            'lims-results',
            this.appStateService.lab.id,
            'workspaces',
            this.appStateService.currentWorkspace.id,
            'empty-queue',
          ]);
          return null;
        }
      }),
      switchMap((queueResponse) => {
        if (!queueResponse) {
          return of(null);
        }

        return this.workspaceService.loadAccession(
          this.appStateService.currentWorkspace._links.accession,
          queueResponse?.accessionId
        );
      }),
      map((accessionHeader: Accession) => {
        this.appStateService.accessionHeader = accessionHeader;
        return accessionHeader;
      }),
      mergeMap((accessionHeader: Accession) => {
        if (accessionHeader) {
          return this.getWorkspaceAccession(accessionHeader).pipe(
            tap((accession) => {
              this.auditService.fireAccessionLoaded(accession.accessionId);
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  private getWorkspaceAccession(accessionHeader: Accession): Observable<CBCAccession> {
    return this.workspaceService.loadWorkspaceAccession(accessionHeader, this.appStateService.currentWorkspace);
  }
}
