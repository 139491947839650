<p class="accept-panel-label">{{ 'LABELS.ACCEPT' | translate }}</p>
<div *ngFor="let panel of panels" class="accept-panel-content" luxLayout="row" luxLayoutAlign="space-between center">
  {{ panel.testCode }}
  <input
    id="{{ 'panel-' + panel.testCode }}"
    (click)="togglePanelAccepted(panel)"
    (keydown.enter)="togglePanelAccepted(panel)"
    [checked]="panel.accept"
    type="checkbox"
    class="spot-toggle" />

  <label
    class="spot-toggle-label"
    data-cy="accept-panel-selector"
    for="{{ 'panel-' + panel.testCode }}"
    #acceptPanelSelector>
  </label>
</div>
