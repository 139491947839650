import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateService {
  constructor(private translate: TranslateService) {}

  unsavedChangesAlert(hasSavableChanges: boolean): boolean {
    const warningText = this.translate.instant('ERRORS_AND_FEEDBACK.HAS_CHANGES_WARNING');

    if (hasSavableChanges) {
      if (confirm(warningText)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
}
