import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lab, Link } from '@lims-common-ux/lux';
import { Observable, catchError, tap } from 'rxjs';
import { AssayCountShortcut, ManualDiffAssay } from '../manual-diff/manual-diff.model';
import { Assay, AssayStatus } from '../../interfaces/assay.interface';
import { AppStateService } from '../../app-state.service';
import { WorkspaceConfig } from './workspace-config.model';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceConfigService {
  shortCuts: AssayCountShortcut[];
  nrbcAssayId: string;
  wbcAssayId: string;
  cellLineAssociations: any;
  cellLineAbsoluteAssayIds: string[];
  acceptPanelsEnabled: boolean;

  allowedTargets: number[] = [25, 50, 100, 200, 400, 500];
  defaultTarget = 100;

  manualDiffAssays: ManualDiffAssay[] = [];
  manualDiffCountsLink: Link;
  addManualDiffLink: Link;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  getLabWorkspaceConfig(workspaceConfigLink: Link, lab: Lab): Observable<WorkspaceConfig> {
    return this.http.get<any>(workspaceConfigLink.href, { params: { labId: lab.id } }).pipe(
      catchError((err) => {
        // can't configure workspace
        throw new Error(err);
      }),
      tap((response) => {
        this.shortCuts = response.countShortcuts;
        this.nrbcAssayId = response.nrbcAssay;
        this.wbcAssayId = response.wbcAssay;
        this.cellLineAssociations = response.cellLines;
        this.cellLineAbsoluteAssayIds = Object.values(this.cellLineAssociations);
        this.acceptPanelsEnabled = response.acceptPanelsEnabled;
      })
    );
  }

  assignConfig(): void {
    this.manualDiffAssays = [];

    if (!this.appStateService.accession?.assays || this.appStateService.accession.assays.length < 1) {
      throw new Error('Can not assign assay configuration. No accession assays available');
    }

    // Order of assays matters and should be determined by the sequence in which an assay appears in the accession
    // Gather accession assays with mapped shortcuts in configuration
    const mappedAccessionAssays = this.appStateService.accession?.assays.filter((assay) => {
      const found = this.shortCuts?.find(
        (assayCountShortcut: AssayCountShortcut) => assay.standardIdexxAssay === assayCountShortcut.standardIdexxAssay
      );

      if (found) {
        return assay;
      }
    });

    mappedAccessionAssays?.forEach((assay: Assay) => {
      if (assay.status === AssayStatus.CANCELED) {
        return;
      }
      const found = this.shortCuts?.find(
        (assayCountShortcut: AssayCountShortcut) => assay.standardIdexxAssay === assayCountShortcut.standardIdexxAssay
      );

      if (found) {
        const resultPresentation = this.resultPresentation(assay);

        const configItem = new ManualDiffAssay(
          null,
          0,
          assay.testCode,
          resultPresentation ? resultPresentation : null,
          '',
          found.key,
          found.standardIdexxAssay
        );

        this.manualDiffAssays.push(configItem);
      }
    });
  }

  initializeManualDiffAssays(): ManualDiffAssay[] {
    this.assignConfig();

    this.manualDiffCountsLink = this.appStateService.accession._links?.manualDiffCount;
    this.addManualDiffLink = this.appStateService.accession._links?.addManualDiff;

    return this.manualDiffAssays;
  }

  getAllowedKeys(): string[] {
    return this.manualDiffAssays.map((manualDiffAssay) => manualDiffAssay.shortCut);
  }

  resultPresentation(assay: Assay) {
    let presentation;

    if (assay.result?.value) {
      if (assay.result.value.noResult) {
        presentation = {
          type: 'NO_RESULT',
        };
      } else {
        presentation = {
          type: 'RESULT',
          value: assay.result.value.emptyResult ? null : assay.result.value,
        };
      }
    }

    if (assay.status === 'REPEAT_REQUESTED') {
      presentation = {
        type: 'REPEAT_REQUESTED',
      };
    }

    return presentation;
  }
}
