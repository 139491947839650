import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualDiffComponent } from './manual-diff.component';
import { WorkspaceConfigService } from '../workspace/workspace-config.service';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { LuxLayoutModule, ModalContainerModule } from '@lims-common-ux/lux';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [ManualDiffComponent],
  imports: [CommonModule, TranslateModule.forChild({}), ModalContainerModule, LuxLayoutModule, ReactiveFormsModule],
  providers: [WorkspaceConfigService, TranslatePipe],
  exports: [ManualDiffComponent],
})
export class ManualDiffModule {}
