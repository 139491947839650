import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { ResultInterval } from '../../../interfaces/assay.interface';
import { AccessionService } from '../../accession/accession.service';

@Directive({
  selector: '[appSemiQuantitativeValue]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SemiQuantitativeValueDirective,
      multi: true,
    },
  ],
})
export class SemiQuantitativeValueDirective implements Validator, OnChanges {
  // This is a 'shared' input with the component to get the initial state.
  @Input('appSemiQuantitativeValue') initialValue: string;

  @Input()
  noResult: boolean;

  @Input()
  repeatRequested: boolean;

  @Input()
  intervals: ResultInterval[] = [];

  onChange: () => void;

  constructor(private assayService: AccessionService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.onChange && (changes.noResult || changes.repeatRequested)) {
      window.requestAnimationFrame(() => {
        this.onChange();
      });
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn;
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    // Escape hatch, no errors present when their is no result present
    if (this.noResult || this.repeatRequested) {
      return null;
    }
    const val = control.value;

    if (val) {
      return this.isValid(val);
    } else {
      return this.isRequired(control);
    }
  }

  private isRequired(control: AbstractControl): { [key: string]: any } | null {
    if (!this.initialValue || this.initialValue === '') {
      control.markAsPristine();
      return null;
    } else if (this.initialValue) {
      return { error: true };
    } else {
      return null;
    }
  }

  private isValid(val: any): { [key: string]: any } | null {
    if (
      isNaN(val) ||
      val < 0 ||
      !Number.isInteger(parseFloat(val)) ||
      val.indexOf(',') !== -1 ||
      val.indexOf('.') !== -1
    ) {
      return { error: true };
    } else {
      if (this.assayService.getRangeDisplayByCount(this.intervals, val) === '') {
        return { invalidRange: true };
      }
      return null;
    }
  }
}
