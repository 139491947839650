import { Injectable } from '@angular/core';
import { Assay } from '../../interfaces/assay.interface';

export class AssaysAndVersions {
  testCode: string;
  version: number;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class AssayWrapperService {
  private initialAssaysAndVersions: AssaysAndVersions[];
  private currentAssaysAndVersions: AssaysAndVersions[];

  constructor() {}

  private getAssaysAndVersion(assays: Assay[]) {
    return assays.map((assay) => {
      return {
        testCode: assay.testCode,
        version: assay.operationalId?.version,
        status: assay.status,
      };
    });
  }

  setInitialAssaysAndVersions(assays: Assay[]): void {
    this.currentAssaysAndVersions = []; // make sure we don't mix different sets of assays.
    this.initialAssaysAndVersions = this.getAssaysAndVersion(assays);
  }

  setCurrentAssaysAndVersions(assays: Assay[]): void {
    if (!this.initialAssaysAndVersions) {
      throw new Error('No initial versions of assays set.');
    }
    this.currentAssaysAndVersions = this.getAssaysAndVersion(assays);
  }

  getSavedAssays() {
    const allSavedAssays: AssaysAndVersions[] = [];

    this.currentAssaysAndVersions?.forEach((versionedAssay) => {
      const savedAssay = this.initialAssaysAndVersions?.find((initialVersionedAssay) => {
        if (
          initialVersionedAssay.testCode === versionedAssay.testCode &&
          initialVersionedAssay.version !== versionedAssay.version &&
          versionedAssay.status !== 'OPENED'
        ) {
          // We look at the status because our 'version' here is the Accession version, and this is a best guess
          // at what has been saved. If an assay is still open, we know it has NOT been saved and we don't want to
          // change it's appearance.
          return versionedAssay;
        }
      });

      if (savedAssay) {
        return allSavedAssays.push(savedAssay);
      }
    });

    return allSavedAssays.length > 0 ? allSavedAssays : null;
  }

  reset() {
    this.initialAssaysAndVersions = [];
    this.currentAssaysAndVersions = [];
  }
}
