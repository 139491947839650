import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of, catchError, tap, switchMap } from 'rxjs';

import { CBCAccession, WorkspaceAccessionService } from '../workspace-accession.service';
import { AppStateService } from '../../app-state.service';
import { AuditService, GlobalErrorHandlerService, LoggerService } from '@lims-common-ux/lux';

@Injectable({ providedIn: 'root' })
export class AccessionResolver {
  constructor(
    private appStateService: AppStateService,
    private loggerService: LoggerService,
    private globalErrorHandler: GlobalErrorHandlerService,
    private workspaceAccessionService: WorkspaceAccessionService,
    private auditService: AuditService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CBCAccession> {
    const accessionId = route.paramMap.get('accession');
    const accessionLoadErrorMsg = 'Error loading accession. Will redirect user to the home page ';
    this.loggerService.logAction('resolve-accession', accessionId);
    return this.workspaceAccessionService.loadAccession(this.appStateService.accessionLink, accessionId).pipe(
      catchError((err) => {
        console.error(accessionLoadErrorMsg);
        this.loggerService.logAction('resolve-accession-error', accessionId);
        this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]).then(() => {
          this.globalErrorHandler.handleError(err);
        });
        return EMPTY;
      }),
      // workspace might be in another lab, in which case the workspace resolver would not find it. This will set the
      // workspace from the accession instead, if available.
      switchMap((header) => {
        if (!this.appStateService.currentWorkspace) {
          const workspaceId = route.parent.paramMap.get('workspace');
          this.appStateService.currentWorkspace = header._embedded.workspaces.find(
            (wrk) => wrk.id === workspaceId && wrk.workspaceType === 'CBC'
          );
          if (this.appStateService.currentWorkspace == null) {
            const workspaceNotFoundErrorMsg = `Workspace ${workspaceId} could not be found on accession ${accessionId} in lab ${this.appStateService.lab.id}`;
            this.loggerService.logAction('workspace-not-found-on-accession-error', workspaceNotFoundErrorMsg);
            this.globalErrorHandler.handleError(new Error(workspaceNotFoundErrorMsg));
            this.router.navigate(['/', 'lims-results', this.appStateService.lab.id]);
            return EMPTY;
          }
        }
        return of(header);
      }),
      switchMap((resultAccession) => {
        this.appStateService.accessionHeader = resultAccession;
        return this.workspaceAccessionService.loadWorkspaceAccession(
          this.appStateService.accessionHeader,
          this.appStateService.currentWorkspace
        );
      }),
      tap((cbcAccession) => {
        this.loggerService.logAction('accession-loaded', accessionId);
        this.appStateService.queueWorkspace = false;
        // only say we loaded this accession if we are actually in the correct workspace.
        this.auditService.fireAccessionLoaded(cbcAccession.accessionId);
      })
    );
  }
}
