import { Pipe, PipeTransform } from '@angular/core';
import { AppStateService } from '../app-state.service';
import { AssayStatus } from '../interfaces/assay.interface';

@Pipe({
  name: 'isCanceledAssay',
})
export class IsCanceledAssayPipe implements PipeTransform {
  constructor(private appStateService: AppStateService) {}

  transform(testCode: string): boolean {
    return this.appStateService.accession.assays.some(
      (assay) => assay.testCode === testCode && assay.status === AssayStatus.CANCELED
    );
  }
}
