<div
  #assayWrapper
  *ngIf="assay"
  data-cy="assay-wrapper"
  class="assay-component-wrapper"
  luxLayout="row"
  luxLayoutAlign="start start"
  style="position: relative"
  [ngClass]="[presentationClass, selected ? 'SELECTED' : '']"
  (focusin)="handleFocusIn()"
  (dblclick)="handleDoubleClick($event)"
  (mousedown)="handleClick($event)">
  <app-assay-comments
    [name]="assay.testCode + '_COMMENTS'"
    [(ngModel)]="assay.comments"
    [commentCount]="assay.comments?.length">
  </app-assay-comments>
  <!-- CARD BADGES (E.G. SELECTED, RELEASED) -->
  <div luxLayout="column" class="assay-indicators">
    <div class="assay-indicator assay-indicator-selected">
      <span>
        <svg class="spot-icon assay-selected-icon" data-cy="assay-selected-icon" aria-labelledby="title">
          <title>{{ 'LABELS.SELECTED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#show"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-released">
      <!-- released assays render a start on the top left corner when not selected -->
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-released-indicator'"
          class="spot-icon assay-released-icon"
          aria-labelledby="title">
          <title>{{ 'LABELS.RELEASED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#favorite-featured"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-technician-review">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-alert-indicator'"
          class="spot-icon assay-alert-icon"
          data-cy="assay-alert-icon"
          aria-labelledby="title">
          <title>{{ 'LABELS.TECHNICIAN_REVIEW' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#alert-notification"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-has-changes">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-has-changes-indicator'"
          aria-labelledby="title"
          class="spot-icon assay-has-changes-icon"
          data-cy="assay-has-changes-icon">
          <title>{{ 'LABELS.HAS_CHANGES' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#save"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-has-transformed-value" data-cy="assay-has-transformed-value-icon">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-has-transformed-value-indicator'"
          aria-labelledby="title"
          class="spot-icon assay-has-transformed-value-icon">
          <title>{{ assay?.result?.transformedValue }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#info"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-canceled">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-canceled-indicator'"
          aria-labelledby="title"
          class="spot-icon assay-canceled-icon"
          data-cy="assay-canceled-icon">
          <title>{{ 'LABELS.CANCELED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#cancel"></use>
        </svg>
      </span>
    </div>
  </div>
  <!-- /CARD BADGES -->

  <!-- CARD WRAPPER -->
  <div
    class="assay-wrapper"
    data-cy="assay-wrapper"
    [ngClass]="assay.resultDefinition?.valueType"
    [attr.data-cy-assay]="assay.testCode">
    <div
      luxLayout="column"
      class="assay"
      [ngClass]="[
        assay.resultDefinition?.valueType === 'DEFINED_MULTI_TEXT' ||
        assay.resultDefinition?.valueType === 'SEMI_QUANTITATIVE_COMBO'
          ? 'wide-assay'
          : '',
      ]"
      data-cy="assay">
      <!-- CARD HEADER -->
      <div class="assay-header" luxLayout="row">
        <div
          class="assay-header-left result-name spot-typography__heading--level-6"
          luxFlex="100%"
          data-cy="assay-name"
          luxLayoutAlign="start">
          {{ assay.testCode }}
        </div>
        <div
          luxLayoutAlign="end end"
          class="assay-header-right"
          [ngClass]="assay.comments && assay.comments?.length ? 'hasComments' : ''"
          luxLayout="row">
          <svg
            *ngIf="assay.comments && assay.comments?.length"
            class="spot-icon assay-comment-icon"
            data-cy="assay-comment-icon"
            aria-labelledby="title">
            <title>{{ 'LABELS.HAS_COMMENTS' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#communicate"></use>
          </svg>
        </div>
      </div>
      <!-- /CARD HEADER -->

      <!-- CARD BODY (CONTAINS INPUTS) -->
      <div #assayBody class="assay-body" luxLayoutAlign="center center" [ngSwitch]="assay.resultDefinition?.valueType">
        <lux-defined-text-result
          *ngSwitchCase="'DEFINED_TEXT'"
          #resultInput
          luxFlex
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [showPrefix]="true"
          [shortCodes]="assay?.shortCodes"
          [disabled]="!assay.canModify"
          (selected)="focusNext($event)"
          [resultOptions]="assay.resultDefinition.types">
        </lux-defined-text-result>
        <app-semi-quantitative-result-combo
          #resultInput
          luxFlex
          [editMode]="editMode"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [shortCodes]="assay.shortCodes"
          [initialValue]="assay.result?.value"
          [(noResult)]="assay.updatedResult.noResult"
          [showPrefix]="true"
          [resultOptions]="assay.resultDefinition.types"
          [intervals]="assay.resultDefinition.intervals"
          [valueCombinations]="assay.resultDefinition.valueCombinations"
          [repeatRequested]="assay?.repeatRequested"
          [disabled]="!assay.canModify"
          (noneSeen)="focusNext($event)"
          *ngSwitchCase="'SEMI_QUANTITATIVE_COMBO'">
        </app-semi-quantitative-result-combo>
        <app-defined-multi-text
          #resultInput
          luxFlex
          [editMode]="editMode"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [initialValue]="assay.result?.value"
          [(noResult)]="assay.updatedResult.noResult"
          [shortCodes]="assay.shortCodes"
          [showPrefix]="true"
          [resultOptions]="assay.resultDefinition.types"
          [repeatRequested]="assay?.repeatRequested"
          [disabled]="!assay.canModify"
          (noneSeen)="focusNext($event)"
          *ngSwitchCase="'DEFINED_MULTI_TEXT'">
        </app-defined-multi-text>
        <app-semi-quantitative-result-input
          #resultInput
          luxFlex
          [appSemiQuantitativeValue]="assay.result.value ? assay.result.value : ''"
          [intervals]="assay.resultDefinition.intervals"
          [name]="assay.testCode"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [repeatRequested]="assay?.repeatRequested"
          [disabled]="!assay.canModify"
          (resultChanged)="focusNext($event)"
          *ngSwitchCase="'SEMI_QUANTITATIVE'">
        </app-semi-quantitative-result-input>
        <app-free-text
          #resultInput
          luxFlex
          [maxlength]="25"
          [name]="assay.testCode"
          [repeatRequested]="assay.repeatRequested"
          [(noResult)]="assay.updatedResult.noResult"
          [(ngModel)]="assay.updatedResult.value"
          [disabled]="!assay.canModify"
          *ngSwitchCase="'FREE_TEXT'">
        </app-free-text>
        <a href="javaScript:void(0);" class="assay-value" *ngSwitchDefault #displayValueLink data-cy="assay-value">
          <span>&nbsp;&nbsp;</span>
        </a>
      </div>
      <!-- /CARD BODY -->

      <!-- PERFORMING LAB ID (TRANSFERS) -->
      <div
        *ngIf="
          assay.status === 'OPENED' && lab.id !== assay.expectedPerformingLab && assay?.updatedResult.value === null
        "
        class="assay-body-background"
        luxLayout="column"
        luxLayoutAlign="center"
        [ngSwitch]="assay.resultDefinition?.valueType">
        <span data-cy="expected-performing-lab">{{ assay.expectedPerformingLab }}</span>
      </div>
      <!-- /PERFORMING LAB ID -->
    </div>
  </div>
  <!-- /CARD WRAPPER -->
</div>
