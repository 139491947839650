import { Component, Input } from '@angular/core';
import { RunResult } from '../workspace-accession.service';

@Component({
  selector: 'app-run-result',
  templateUrl: './run-result.component.html',
  styleUrls: ['./run-result.component.scss'],
})
export class RunResultComponent {
  @Input() result: RunResult;
  @Input() canceled = false;
  @Input() selected = false;
}
