import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Comment } from '@lims-common-ux/lux';

/**
 * This is here purely as a helper for the form to know when comments have been changed and the accession is able to
 * be saved. It has no UI representation, and never actually updates the comments itself.
 */
@Component({
  selector: 'app-assay-comments',
  templateUrl: './assay-comments.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AssayCommentsComponent),
      multi: true,
    },
  ],
})
export class AssayCommentsComponent implements ControlValueAccessor, OnChanges {
  @Input()
  commentCount: number;

  private commentsSet = false;
  private comments: Comment[];
  private onChange: (any) => void;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.commentsSet) {
      this.commentsSet = true;
    } else {
      /**
       * This is ugly, but, when we add a comment, the array is updated in place (and writeValue is NOT called) and the
       * values all line up here so its safe to trigger the change. When removing, we reset the array value itself.
       * The changes fire before writeValue is called so we do not want to fire onChange with the old values.
       */
      if (this.commentCount > 0 && this.commentCount === this.comments?.length) {
        this.onChange(this.comments);
      }
    }
  }

  writeValue(comments: Comment[]) {
    this.comments = comments;
    // We have to deal with removing all comments here. The onChange deals with additions where the array doesn't change.
    if (this.commentsSet && (!this.comments || this.comments.length === 0)) {
      if (this.onChange) {
        this.onChange(comments);
      }
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(fn: any) {
    // empty on purpose, hidden component that would never get touched by the user.
  }
}
