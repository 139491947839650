<button
  type="button"
  data-spot-trigger-modal="default-modal-example"
  data-cy="repeat-run"
  class="spot-button spot-button--secondary"
  title="{{ 'LABELS.REPEAT_RUN' | translate }}"
  (click)="repeatRun()"
  [disabled]="!runIsRepeatable || hasChanges">
  {{ 'LABELS.REPEAT_RUN' | translate }}
</button>
