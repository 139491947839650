import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FreeTextComponent),
      multi: true,
    },
  ],
})
export class FreeTextComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('freeTextResultInputWrapper', { static: false })
  freeTextInputWrapper: ElementRef;

  @ViewChild('input', { static: false })
  input: ElementRef;

  @Input()
  val = '';

  @Input()
  disabled = false;

  @Input()
  maxlength: number;

  @Input()
  noResult: boolean;

  @Input()
  repeatRequested: boolean;

  @Input()
  tabindex = 1;

  @Output()
  noResultChange = new EventEmitter<boolean>();

  valueSub: Subscription;

  control: AbstractControl;

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  get value() {
    return this.val;
  }

  set value(val: string) {
    if (val !== null) {
      this.val = val.trim();

      if (val) {
        this.noResult = false;
        this.noResultChange.emit(this.noResult);
      }
    }
  }

  constructor(private injector: Injector) {}

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;

    this.valueSub = this.control.valueChanges.subscribe((value: string) => {
      this.setErrorState();
    });
  }

  ngOnDestroy() {
    this.valueSub?.unsubscribe();
  }

  handleInput($event) {
    this.writeValue($event.target.value);
    this.control.markAsDirty();
    this.setErrorState();
    this.onChange(this.value);
  }

  // spaces for the first character are not allowed
  handleBlockedKey($event) {
    if (this.val.length === 0) {
      $event.preventDefault();
      $event.stopImmediatePropagation();
      return false;
    } else {
      return true;
    }
  }

  focusInput() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  handleFocusOut($event) {
    this.onTouched($event);
    if (this.control.dirty && !this.control.errors) {
      this.onChange(this.value);
    }
  }

  writeValue(value: string) {
    if (value === null) {
      value = '';
    }

    if (value !== this.value) {
      this.value = value;
    }
  }

  setErrorState() {
    const isInvalid = !this.value.trim() && !this.noResult && !this.repeatRequested && this.control.dirty;

    if (isInvalid) {
      let errors: ValidationErrors;
      errors = { inputError: true };
      this.control?.setErrors(errors);
    } else {
      this.control?.setErrors(null);
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }
}
