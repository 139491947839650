import { Comment } from '@lims-common-ux/lux';
import { Assay } from '../../interfaces/assay.interface';
import { Panel } from '../../panel/panel.interface';

export interface Command<T> {
  operationalId: any;
  value?: T;
  actionType: ActionType;
}

export enum ActionType {
  ACCEPT_PANEL = 'ACCEPT_PANEL',
  UPDATE_RESULT = 'UPDATE_RESULT',
  UPDATE_ASSAY_COMMENTS = 'UPDATE_ASSAY_COMMENTS',
  TECHNICALLY_ACCEPT = 'TECHNICALLY_ACCEPT',
  UPDATE_PANEL_COMMENTS = 'UPDATE_PANEL_COMMENTS',
}

export class AcceptCommand implements Command<Assay['updatedResult']['value']> {
  constructor(
    assay: Assay,
    public operationalId = assay.operationalId,
    public value = assay.updatedResult.value,
    public actionType = ActionType.TECHNICALLY_ACCEPT
  ) {}
}

export class CommentCommand implements Command<Comment['id'][]> {
  constructor(
    assay: Assay,
    public operationalId = assay.operationalId,
    public value = assay.comments && assay.comments.map((comment: Comment) => comment.id),
    public actionType = ActionType.UPDATE_ASSAY_COMMENTS
  ) {}
}

export class PanelCommentCommand implements Command<Comment['id'][]> {
  constructor(
    panel: Panel,
    public operationalId = panel.operationalId,
    public value = panel.comments && panel.comments.map((comment: Comment) => comment.id),
    public actionType = ActionType.UPDATE_PANEL_COMMENTS
  ) {}
}

export class PanelAcceptCommand implements Command<Panel['testCode'][]> {
  operationalId: any;
  constructor(
    panels: Panel[],
    operationalId: any,
    public value = panels.map((panel) => panel.testCode),
    public actionType = ActionType.ACCEPT_PANEL
  ) {
    this.operationalId = operationalId;
  }
}

export class UpdateCommand implements Command<Assay['updatedResult']['value'] | { noResult: boolean }> {
  constructor(
    assay: Assay,
    public operationalId = assay.operationalId,
    public value = assay.updatedResult.noResult ? { noResult: true } : assay.updatedResult.value,
    public actionType = ActionType.UPDATE_RESULT
  ) {}
}
