<svg
  class="spot-icon spot-button__icon spot-button__icon--left"
  aria-labelledby="title"
  *ngIf="result?.result?.value?.noResult">
  <title>{{ 'LABELS.NO_RESULT' | translate }}</title>
  <use xlink:href="../../assets/icons/spot_icons.svg#blocked"></use>
</svg>
<ng-container *ngIf="!result?.result?.value?.emptyResult && !result?.result?.value?.noResult">
  <span [ngClass]="{ canceled: canceled && !selected }">{{ result?.result?.value }}</span>
</ng-container>
