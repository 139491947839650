import { EventEmitter, Inject, Injectable } from '@angular/core';

import { Lab, LabsService, Link, User, WorkQueue, Workspace } from '@lims-common-ux/lux';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';

import { BehaviorSubject, Observable, of, shareReplay, switchMap } from 'rxjs';

import { ApplicationInitService } from './application-init.service';
import { Panel } from './panel/panel.interface';
import { CBCAccession } from './workspace/workspace-accession.service';
import { Assay } from './interfaces/assay.interface';
import { DEFAULT_TITLE } from './app.defaults';
import { ResultsDataResource } from '@lims-common-ux/lux/lib/data-resources/results-data-resource.interface';

export interface AppState {
  workspaces: Workspace[];
  labs: Lab[];
  lab: Lab;
  accession: CBCAccession;
  currentWorkspace: Workspace;
  accessionHeader?: AccessionHeader;
  panels?: Observable<Panel[]>;
  hasSavableChanges: Observable<boolean>;
  currentAssay?: Assay;
  user: User;
  commentsDataSource: Link;
}

@Injectable({
  providedIn: 'root',
})
export class AppStateService implements AppState {
  private _accession: CBCAccession = null;
  private _accession$: BehaviorSubject<CBCAccession> = new BehaviorSubject<CBCAccession>(this._accession);
  private _accessionHeader: AccessionHeader;
  private _currentAssay: Assay = null;
  public currentAssaySub = new BehaviorSubject<Assay>(this._currentAssay);
  private isInitialized = false;
  private _lab: Lab = null;
  private _loading = true;
  private _workspaces: Workspace[];
  public focusFirstAssayEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public accessionChangedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  private resultsResource: ResultsDataResource;

  queueWorkspace = false;
  workspaceQueueNextUrl: Link;
  workQueues: WorkQueue[];
  currentWorkspace: Workspace;

  // Needed for can deactivate guard
  private hasSavableChangesSub = new BehaviorSubject<Observable<boolean>>(of(false));

  constructor(
    @Inject('Document') private document: any,
    private initService: ApplicationInitService,
    private labsService: LabsService
  ) {}

  configureSharedLinks(resource: ResultsDataResource) {
    this.resultsResource = resource;
  }

  triggerFocusFirstAssay() {
    this.focusFirstAssayEvent.emit(true);
  }

  triggerAccessionChange() {
    this.accessionChangedEvent.emit(true);
  }

  get loading(): boolean {
    return this._loading;
  }

  set loading(isLoading: boolean) {
    if (!this.isInitialized && !this._loading) {
      this.isInitialized = true;
    }

    if (isLoading) {
      this.document.getElementById('app-loader').style.display = 'block';

      if (this.isInitialized) {
        this.document.getElementById('app-loader').classList.add('waiting-on-interaction');
      }
    } else {
      this.document.getElementById('app-loader').style.display = 'none';
    }

    this._loading = isLoading;
  }

  set hasSavableChanges(obs: Observable<boolean>) {
    this.hasSavableChangesSub.next(obs.pipe(shareReplay(1)));
  }

  get hasSavableChanges(): Observable<boolean> {
    return this.hasSavableChangesSub.asObservable().pipe(switchMap((obs) => obs));
  }

  get commentsDataSource(): Link {
    return this.verifyAndGetResultLinks('comments');
  }

  get accessionLink(): Link {
    return this.verifyAndGetResultLinks('accession');
  }

  get accessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionSearch');
  }

  get addLabNoteLink(): Link {
    return this.verifyAndGetResultLinks('addLabNote');
  }

  get advancedAccessionSearchLink(): Link {
    return this.verifyAndGetResultLinks('accessionAdvancedSearch');
  }

  get getLabNotesLink(): Link {
    this.verifyAndGetResultLinks('getLabNotes');
    return this.resultsResource._links.getLabNotes;
  }

  get workspaceConfigSource(): Link {
    return this.initService.staticAppData.pageResource._links.config;
  }

  get workspaces(): Workspace[] {
    return this._workspaces;
  }

  set workspaces(wsArr: Workspace[]) {
    this._workspaces = wsArr;
  }

  get labs(): Lab[] {
    return this.initService.staticAppData.labs;
  }

  set lab(lab: Lab) {
    this._lab = lab;
    this.labsService.currentLab = lab;
    this.currentWorkspace = null;
  }

  get lab(): Lab {
    return this._lab;
  }

  get env(): string {
    return this.initService.staticAppData.environment;
  }

  get defaultPageTitle(): string {
    let title = `${DEFAULT_TITLE}`;

    if (this.currentWorkspace?.name) {
      title = this.currentWorkspace?.name;
    }

    if (this.initService.staticAppData.environment === 'uat' || this.initService.staticAppData.environment === 'exp') {
      title += ` (${this.initService.staticAppData.environment})`;
    }

    return title;
  }

  get accessionHeader(): AccessionHeader {
    return this._accessionHeader;
  }

  set accessionHeader(accessionHeader: AccessionHeader) {
    this._accessionHeader = accessionHeader;
  }

  get accession(): CBCAccession {
    return this._accession;
  }

  set accession(accession: CBCAccession) {
    this._accession = accession;
    this._accession$.next(accession);
  }

  get accession$(): Observable<CBCAccession> {
    return this._accession$.asObservable();
  }

  get user(): User {
    return this.initService.staticAppData.currentUser;
  }

  get currentAssay(): Assay {
    return this._currentAssay;
  }

  set currentAssay(assay: Assay) {
    this._currentAssay = assay;
    this.currentAssaySub.next(this._currentAssay);
  }

  private verifyAndGetResultLinks(linkName: string): Link {
    const link = this.resultsResource?._links[linkName];
    if (link == null) {
      throw new Error(
        'Result links not set when looking for link ' +
          linkName +
          '. Please use `configureSharedLinks` to set this data appropriately.'
      );
    } else {
      return link;
    }
  }
}
