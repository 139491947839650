<div
  #freeTextResultInputWrapper
  class="free-text-input-wrapper spot-form__field-group"
  [class.spot-form--error]="control.errors"
  (focusout)="handleFocusOut($event)">
  <textarea
    #input
    [attr.tabindex]="tabindex"
    [attr.maxlength]="maxlength"
    [readonly]="disabled"
    (keydown.space)="handleBlockedKey($event)"
    (keydown.enter)="handleBlockedKey($event)"
    (keydown.shift.enter)="handleBlockedKey($event)"
    (input)="handleInput($event)"
    autocomplete="off"
    [value]="val"
    class="run-result-input spot-form__input"
    data-cy="free-text-result-input"
    name="runResultValue"
    type="text">
  </textarea>
  <span *ngIf="control.errors" class="spot-form__field-error" id="error-must-be-number" role="alert">
    <span class="spot-form__field-error-text" data-cy="result-value-error"
      >{{ 'ERRORS_AND_FEEDBACK.MUST_ENTER_RESULT_VALUE' | translate }}
    </span>
  </span>
</div>
