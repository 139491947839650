import { Pipe, PipeTransform } from '@angular/core';
import { Run } from './workspace-accession.service';

@Pipe({
  name: 'currentRun',
})
export class CurrentRunPipe implements PipeTransform {
  transform(runs: null, currentRun: number): void;
  transform(runs: Run[] | null, currentRun: null): null;
  transform(runs: Run[], currentRun: number): Run;

  transform(runs: Run[] | null, currentRun: number | null): Run | null {
    if (!runs && currentRun) {
      throw new Error(`Inconsistent model in CBC Accession, currentRun should not exist: ${currentRun}`);
    }
    if (runs == null) {
      // Initial execution of the pipe, still loading
      return null;
    }
    if (runs && currentRun == null) {
      // No run available, repeating the run
      return null;
    }
    const result = runs.find((run) => run.runNumber === currentRun);
    if (!result) {
      throw new Error('Invalid run number, it does not exist in list of runs');
    }
    return result;
  }
}
