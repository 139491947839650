import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AccessionService } from '../../accession/accession.service';
import { ResultInterval } from '../../../interfaces/assay.interface';

@Component({
  selector: 'app-semi-quantitative-result-input',
  templateUrl: './semi-quantitative-result-input.component.html',
  styleUrls: ['./semi-quantitative-result-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SemiQuantitativeResultInputComponent),
      multi: true,
    },
  ],
})
export class SemiQuantitativeResultInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild('input', { static: false })
  input: ElementRef;

  @ViewChild('semiQuantitativeResultInputWrapper', { static: false })
  cmpWrapper: ElementRef;

  // This is a'shared' input with the validator to get the initial state.
  // tslint:disable-next-line:no-input-rename
  @Input('appSemiQuantitativeValue') initialValue;

  @Input()
  val = '';

  @Input()
  placeholder = '';

  @Input()
  intervals: ResultInterval[] = [];

  @Input()
  hidden = true;

  @Input()
  tabindex = 1;

  @Input()
  disabled = false;

  @Input()
  name;

  @Output()
  resultChanged = new EventEmitter<true>();

  @Input()
  repeatRequested: boolean;

  @Input()
  noResult: boolean;
  @Output()
  noResultChange = new EventEmitter<boolean>();

  showError = false;

  control: AbstractControl;
  controlValueWithinLimits = true;

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = val;
    if (this.val) {
      this.noResult = false;
      this.noResultChange.emit(this.noResult);
    }
  }

  constructor(
    private assayService: AccessionService,
    private translate: TranslateService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    const model = this.injector.get(NgControl);
    this.control = model.control;

    // This updates the display value when we have an assay with a saved value. This normally only happens on blur events
    // but the user should see the interval when the assay is loaded, not the entered number.
    if (this.initialValue && this.initialValue !== '') {
      setTimeout(() => {
        this.updateDisplay(this.initialValue);
      });
    }
  }

  writeValue(value: string) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = onTouch;
  }

  focusInput() {
    this.input.nativeElement.focus();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  handleInput($event) {
    let val = $event.target.value.trim();

    if (val.indexOf(',') !== -1) {
      val = val.replace(',', '.');
    }
    this.writeValue(val);
    this.updateDisplay(val, false);
    this.control.markAsDirty();
    this.onChange(this.value);
  }

  handleEnter($event) {
    if (this.disabled) {
      return;
    }

    if (!this.showError) {
      this.handleBlur($event);
      this.resultChanged.emit(true);
      this.onChange(this.value);
    }
  }

  handleBackspace($event) {
    if (this.disabled) {
      return;
    }

    let clear = false;
    const currentValue = this.input.nativeElement.value;

    this.intervals.forEach((interval) => {
      if (currentValue === interval.customerFacingText) {
        clear = true;
      }
    });

    if (clear) {
      if (this.value) {
        this.writeValue('');
      }
    }
    this.onChange(this.value);
  }

  handleFocusOut($event) {
    this.onTouched($event);
    if (this.control.dirty) {
      this.onChange(this.value);
    }
  }

  handleBlur($event) {
    this.updateDisplay($event.target.value);
  }

  updateDisplay(currentValue, updateDisplayResult = true) {
    if ((currentValue.noResult || this.noResult || this.repeatRequested) && updateDisplayResult) {
      this.input.nativeElement.value = '';
      return;
    }

    if (currentValue.indexOf(',') !== -1) {
      currentValue = currentValue.replace(',', '.');
    }

    // Prevent getRangeDisplayByCount from being called multiple times when nativeElement.value is updated
    let readyToUpdate = true;

    this.intervals.forEach((interval) => {
      if (interval.customerFacingText === currentValue) {
        readyToUpdate = false;
      }
    });

    if (readyToUpdate) {
      const displayResult = this.assayService.getRangeDisplayByCount(this.intervals, currentValue);
      if (displayResult === '') {
        // invalid/missig range, don't change the display.
        return;
      }
      if (updateDisplayResult) {
        this.input.nativeElement.value = displayResult;
      }
    }
  }
}
