export class AssayCountShortcut {
  key: string;
  standardIdexxAssay: string;
}

export class ManualDiffAssay {
  constructor(
    manualDiffAssay: ManualDiffAssay,
    public count: number = manualDiffAssay.count ? manualDiffAssay.count : 0,
    public testCode: string = manualDiffAssay.testCode ? manualDiffAssay.testCode : '',
    public resultPresentation: any = manualDiffAssay?.resultPresentation ? manualDiffAssay.resultPresentation : null,
    public result: string = manualDiffAssay.result ? manualDiffAssay.result : '',
    public shortCut: string = manualDiffAssay.shortCut ? manualDiffAssay.shortCut : '',
    public standardIdexxAssay: string = manualDiffAssay.standardIdexxAssay ? manualDiffAssay.standardIdexxAssay : ''
  ) {}

  incrementCount(): void {
    this.count++;
  }

  decrementCount(): void {
    this.count--;
  }
}
