import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { KeyboardAction, KeyboardService, Lab, LoggerService } from '@lims-common-ux/lux';

import { switchMap, tap } from 'rxjs';

import { AppStateService } from '../../app-state.service';
import { CBCAccession, Run, WorkspaceAccessionService } from '../workspace-accession.service';
import { ModalContainerComponent } from '@lims-common-ux/lux/lib/modal-container/modal-container.component';
import { Event } from '@angular/router';

@Component({
  selector: 'app-run-picker',
  templateUrl: './run-picker.component.html',
  styleUrls: ['./run-picker.component.scss'],
})
export class RunPickerComponent implements OnInit {
  @Input() currentRun: number;
  @Input() disabled: boolean;
  @Input() lab: Lab;
  @Input() runs: Run[] = [];
  // @Input()
  accession: CBCAccession;

  @Output()
  handleNextInQueueClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('nextInQueueButton', { static: false })
  nextInQueueButton!: ElementRef;
  @ViewChild('runPickerButton', { static: false })
  runPickerButton: ElementRef;
  @ViewChildren('runSelector')
  runSelectors: QueryList<ElementRef>;
  @ViewChild('modalContent', { static: false })
  modalContent!: ModalContainerComponent;

  visible = false;

  nextInQueueAction = {
    name: 'modal-next-in-queue',
    eventMatch: { key: 'ArrowRight', altKey: true },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.nextInQueue();
    },
    removeOnMatch: true,
  } as KeyboardAction;

  // This is an override to the overloaded Escape key actions defined in the lims-common-ux lib.
  // NOTE: this configuration is registered on modal open, and removed once fired
  closeRunPicker = {
    name: 'modal-close',
    eventMatch: { key: 'Escape' },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.closeModal();
    },
    removeOnMatch: true,
  } as KeyboardAction;

  closeAndNavigate = {
    name: 'modal-close-and-navigate',
    eventMatch: { key: 'F2' },
    matchCallback: ($evt: KeyboardEvent) => {
      this.closeModal();
    },
    removeOnMatch: true,
  } as KeyboardAction;

  get isDisabled(): boolean {
    let disabled = true;

    if (!this.disabled && this.runs?.length > 1 && this.runs[0]?._links?.select) {
      disabled = false;
    }

    return disabled;
  }

  constructor(
    public appStateService: AppStateService,
    private workspaceAccessionService: WorkspaceAccessionService,
    private keyboardService: KeyboardService,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.appStateService.accession$.subscribe((val) => {
      this.accession = val;
    });
  }

  closeModal() {
    if (!!this.accession?.runMustBePicked || !this.visible) {
      return;
    }
    this.visible = false;
    this.keyboardService.removeAction(this.nextInQueueAction);
    this.keyboardService.removeAction(this.closeAndNavigate);
    this.focusRunPickerButton();
  }

  openModal($event?: Event) {
    // Prevent open via keyboard or mouse click when loading
    // Open modal while loading is ok when not initiated by user (e.g. when navigating through the queue)
    if (this.appStateService.loading && $event) {
      return;
    }
    this.visible = true;

    // On open, we normally focus the modal close button in the Modal Container Component,
    // but that is disabled when runMustBePicked is active.
    // Instead, focus the nextInQueue button if available.
    // Otherwise focus the most recent run selector control.
    if (!!this.accession?.runMustBePicked) {
      this.loggerService.logAction('run-picker-manual-open', {});

      requestAnimationFrame(() => {
        if (this.appStateService.queueWorkspace) {
          this.focusNextInQueueButton();
        } else {
          this.focusLastRunSelector();
        }
      });
    }

    // Register feature modal specific short cut keyboard actions. These are removed/unregistered by configuration each time they are called
    this.registerNextInQueueAction();
    this.registerCloseModalAction();
    this.registerCloseAndNavigateAction();
    this.resetScroll();
  }

  resetScroll(): void {
    setTimeout(() => {
      this.modalContent?.modalContentTop?.nativeElement.scrollIntoView({
        behavior: 'smooth',
      });
    }, 0);
  }

  registerNextInQueueAction() {
    this.keyboardService.addActions([this.nextInQueueAction]);
  }

  registerCloseModalAction() {
    if (!this.accession?.runMustBePicked) {
      this.keyboardService.addActions([this.closeRunPicker]);
    }
  }

  registerCloseAndNavigateAction() {
    this.keyboardService.addActions([this.closeAndNavigate]);
  }

  focusRunPickerButton() {
    this.runPickerButton?.nativeElement.focus();
  }

  focusNextInQueueButton() {
    this.nextInQueueButton?.nativeElement.focus();
  }

  focusLastRunSelector() {
    this.runSelectors.last?.nativeElement.focus();
  }

  setSelectedRun(run: Run): void {
    if (!run._links?.select) {
      const errMsg = 'Can not select a run without a link';
      this.loggerService.logAction('can-not-select-run-error', errMsg);
      throw new Error(errMsg);
    }

    this.closeModal();

    this.appStateService.loading = true;
    this.workspaceAccessionService
      .setSelectedRun(run)
      .pipe(
        switchMap(() =>
          this.workspaceAccessionService.loadWorkspaceAccession(
            this.appStateService.accessionHeader,
            this.appStateService.currentWorkspace
          )
        ),
        tap(() => {
          this.loggerService.logAction('run-selected', run);
        })
      )
      .subscribe();
  }

  nextInQueue(): void {
    if (!this.appStateService.loading && this.appStateService.queueWorkspace) {
      this.closeModal();
      this.handleNextInQueueClick.emit();
    }
  }

  isManualDiffRun(run: Run): boolean {
    return run.runType === 'MANUAL_DIFFERENTIAL';
  }
}
