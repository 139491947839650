import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { CommentsComponent, Lab } from '@lims-common-ux/lux';
import { Comment } from '@lims-common-ux/lux/lib/comments/comment.interface';

import { Subscription } from 'rxjs';

import { AppStateService } from '../../app-state.service';
import { Panel } from '../panel.interface';

export interface CommentsContext {
  comments: Comment[];
}

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  @Input()
  panel: Panel;

  @Input()
  lab: Lab;

  @ViewChild('panelComment', { static: false })
  panelComment: CommentsComponent;

  @Output()
  updated = new EventEmitter<Panel>();
  isExpanded = true;
  commentSearchUrl = '';
  commentsDatasourceSub: Subscription;
  commentContext: CommentsContext = {
    comments: [],
  };

  constructor(private appStateService: AppStateService) {}

  ngOnInit(): void {
    this.commentSearchUrl = this.appStateService.commentsDataSource.href;

    if (this.panel?.comments?.length) {
      this.commentContext.comments = this.panel.comments;
    }
  }

  toggleExpand(): void {
    this.isExpanded = !this.isExpanded;
  }

  onAddComment(comment: Comment): void {
    // we do not try to add duplicates
    if (comment) {
      const existing = this.commentContext.comments.find((existingComment) => {
        return comment.shortCode === existingComment.shortCode;
      });

      if (!existing) {
        this.commentContext.comments.unshift(comment);

        this.panel.comments = this.commentContext.comments;
      }

      // Close search results and return focus to search input
      this.panelComment.resetComments();
      this.panelComment.focusSearchInput();

      this.updated.emit(this.panel);
    }
  }

  onRemoveComment(comment: Comment): void {
    this.commentContext.comments.forEach((existingComment, index) => {
      if (existingComment.shortCode === comment.shortCode) {
        this.commentContext.comments.splice(index, 1);
        this.updated.emit(this.panel);
        this.panelComment.focusSearchInput();
      }
    });
  }
}
