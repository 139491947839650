import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DefinedTextValue, Link } from '@lims-common-ux/lux';
import { Assay, ResultInterval } from '../../interfaces/assay.interface';
import { CBCAccession } from '../workspace-accession.service';
import { Panel } from '../../panel/panel.interface';
import { AppStateService } from '../../app-state.service';
import {
  AcceptCommand,
  CommentCommand,
  PanelAcceptCommand,
  PanelCommentCommand,
  UpdateCommand,
  Command,
} from './command.model';

interface AssayResponse {
  _embedded: {
    assays: Assay[];
  };
}

export interface AssayUpdateResponse {
  updated: [{ _links: { self: Link } }];
}

export class ConflictError {
  constructor(public source: any) {}
}

@Injectable({
  providedIn: 'root',
})
export class AccessionService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService
  ) {}

  /**
   * Sends any assay with a result to the server and returns the array of assays that the server said actually got
   * updated.
   */
  saveAssays(
    accession: CBCAccession,
    assays: Assay[],
    accept: boolean,
    panels: Panel[] = []
  ): Observable<AssayUpdateResponse> {
    const payload: Command<any>[] = assays
      .map((assay) => {
        if (this.hasValue(assay)) {
          return new UpdateCommand(assay);
        } else {
          return null;
        }
      })
      .filter((result) => result !== null);
    const acceptedPanels = panels.filter((panel) => panel.accept);

    // Update payload with assay comments and/or technically accepted status
    assays.forEach((assay: Assay) => {
      payload.push(new CommentCommand(assay));
      // We are passing all assays here, even if they are open. We are asking the backend to accept all the assays it
      // can, and rules may change the state of an assay to be completable even if we don't see it here currently.
      if (accept) {
        payload.push(new AcceptCommand(assay));
      }
    });

    if (panels?.length) {
      panels.forEach((panel: Panel) => {
        payload.push(new PanelCommentCommand(panel));
      });
    }

    if (acceptedPanels.length && !accept) {
      const panelAcceptCommand = new PanelAcceptCommand(acceptedPanels, this.appStateService.accession.operationalId);
      payload.push(panelAcceptCommand);
    }

    return this.http.post<AssayUpdateResponse>(accession._links.saveAssays.href, payload);
  }

  getRangeDisplayByCount(intervals: ResultInterval[], count: number): string {
    if (intervals) {
      const result = intervals.find((interval) => {
        return count >= interval.low && (interval.high === undefined || count <= interval.high);
      });

      if (result) {
        return result.customerFacingText;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  hasComments(assay: Assay): boolean {
    return assay?.comments?.length > 0;
  }

  hasValue(assay: Assay): boolean {
    const value = assay?.updatedResult?.value;
    if (
      assay?.updatedResult?.noResult ||
      (Array.isArray(value) && value.length > 0) ||
      (!Array.isArray(value) && value)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getObservationDisplayTextByValue(values: DefinedTextValue[] | string[], value: string): string {
    let result;

    values.forEach((option) => {
      if (option?.code === value) {
        result = option?.display;
      }
    });

    if (!result) {
      result = '';
    }

    return result;
  }

  /**
   * @param onNoResult only called if the state of the assay is actually changed.
   */
  noResult(assay: Assay, onNoResult: () => void) {
    if (!assay.updatedResult?.noResult) {
      assay.updatedResult.value = null;
      assay.updatedResult.noResult = true;
      assay.repeatRequested = false;
      if (onNoResult) {
        onNoResult();
      }
    }
  }
}
