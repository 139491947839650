import { Component, Input } from '@angular/core';

import { switchMap } from 'rxjs';

import { AppStateService } from '../../app-state.service';
import { Run, WorkspaceAccessionService } from '../workspace-accession.service';

@Component({
  selector: 'app-repeat-run',
  templateUrl: './repeat-run.component.html',
})
export class RepeatRunComponent {
  @Input() set run(value: Run | null) {
    if (value) {
      this.repeatRunLink = value._links?.repeatRun?.href;
      this.runIsRepeatable = !!this.repeatRunLink;
    } else {
      this.runIsRepeatable = false;
    }
  }
  @Input() hasChanges: boolean;

  runIsRepeatable = false;
  private repeatRunLink: string;

  constructor(
    private workspaceAccessionService: WorkspaceAccessionService,
    private appStateService: AppStateService
  ) {}

  repeatRun() {
    if (!this.runIsRepeatable || this.appStateService.loading) {
      return;
    }

    this.appStateService.loading = true;
    this.workspaceAccessionService
      .repeatRun(this.repeatRunLink)
      .pipe(
        switchMap(() =>
          this.workspaceAccessionService.loadWorkspaceAccession(
            this.appStateService.accessionHeader,
            this.appStateService.currentWorkspace
          )
        )
      )
      .subscribe();
  }
}
