import { Component, Input } from '@angular/core';
import { Accession as AccessionHeader } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Workspace } from '@lims-common-ux/lux';
import { CBCAccession } from '../../workspace-accession.service';

@Component({
  selector: 'app-workspace-navigation',
  templateUrl: './workspace-navigation.component.html',
  styleUrls: ['./workspace-navigation.component.scss'],
})
export class WorkspaceNavigationComponent {
  @Input()
  currentWorkspace: Workspace;

  @Input()
  workspaceAccession: CBCAccession;

  @Input()
  headerAccession: AccessionHeader;
}
