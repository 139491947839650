import { Component, Input } from '@angular/core';
import { Assay } from '../../../../interfaces/assay.interface';
import { DefinedTextValue } from '@lims-common-ux/lux';
import { AccessionService } from '../../../accession/accession.service';

@Component({
  selector: 'app-details-key',
  templateUrl: './details-key.component.html',
  styleUrls: ['./details-key.component.scss'],
})
export class DetailsKeyComponent {
  @Input()
  assay: Assay;
  @Input()
  definedText = false;

  constructor(private assayService: AccessionService) {}

  shortCodeFor(value: DefinedTextValue): string {
    const codes = this.assay.shortCodes;
    let shortCode = '';
    for (const key in this.assay.shortCodes) {
      if (codes[key] === value.code) {
        shortCode = key;
        break;
      }
    }
    return shortCode;
  }

  getObservationDisplayTextByValue(value: string): string {
    return this.assayService.getObservationDisplayTextByValue(this.assay.resultDefinition.types, value);
  }
}
