import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Link, Workspace, WorkspaceAccession } from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

import { Observable, map, tap } from 'rxjs';

import { AppStateService } from '../app-state.service';
import { Assay, Instrument, Result } from '../interfaces/assay.interface';
import { Panel } from '../panel/panel.interface';
import { WorkspaceConfigService } from './workspace/workspace-config.service';

export enum AssayCategory {
  NON_CELL_LINE,
  CELL_LINE,
  OBSERVATION,
}

export interface RunResult {
  testCode: string;
  result?: Result;
}

export interface Run {
  runNumber: number;
  enteredBy: string;
  timestamp: string;
  instrument: Instrument;
  results: RunResult[];
  operationalId: any;
  runType: string;
  _links?: {
    repeatRun?: Link;
    select?: Link;
  };
}

export interface CBCAccession extends WorkspaceAccession {
  assays: Assay[];
  panels: Panel[];
  currentRun?: number;
  runs?: Run[];
  runMustBePicked?: boolean;
  operationalId: any;
  _links: {
    _self: Link;
    assays: Link; // Not used in this context, but defined in the interface from common
    manualDiffCount: Link;
    addManualDiff: Link;
    saveAssays: Link;
  };
}

interface PanelResponse {
  _embedded: {
    panels: Panel[];
  };
}

@Injectable()
export class WorkspaceAccessionService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private workspaceConfigService: WorkspaceConfigService
  ) {}

  /* Gets AccessionHeader */
  loadAccession(accessionLink: Link, accessionId: string): Observable<Accession> {
    const accessionUrl = accessionLink.href.replace('{accessionId}', accessionId);
    return this.http.get<Accession>(accessionUrl);
  }

  /**
   * Assume we are loading CBC here as we shouldn't be getting details on any other workspace in this
   * context.
   */
  loadWorkspaceAccession(accession: Accession, currentWorkspace: Workspace): Observable<CBCAccession> {
    const accessionWorkspace = accession._embedded.workspaces.filter((ws) => ws.id === currentWorkspace.id)[0];
    return this.http.get<CBCAccession>(accessionWorkspace._links.accession.href).pipe(
      tap((cbcAccession) => {
        /**
         * Sets up the structure for updating result values and defining the expected structure when ever assays are loaded.
         */
        cbcAccession.assays.forEach((assay) => {
          // defaults presentation value on all assays to mirror status
          assay.presentationStatus = assay.status;
          if (assay.result?.value?.emptyResult) {
            assay.updatedResult = {
              value: null,
              noResult: false,
              emptyResult: true,
            };
          } else if (assay.result) {
            // translates noResult values into separate properties so we can use them in the UI
            assay.updatedResult = {
              value: assay.result.value?.noResult ? '' : this.getResultValue(assay),
              noResult: assay.result.value?.noResult,
              emptyResult: false,
            };
          } else {
            assay.result = { value: null, enteredBy: null, timestamp: null };
            assay.updatedResult = { value: null, noResult: false, emptyResult: false };
          }
        });
      }),
      map((cbcAccession: CBCAccession) => {
        this.appStateService.accession = cbcAccession;
        return cbcAccession;
      })
    );
  }

  private getResultValue(assay: Assay): any {
    return Array.isArray(assay.result.value) ? [...assay.result.value] : assay.result.value;
  }

  repeatRun(repeatRunLink: string): Observable<any> {
    return this.http.post<void>(repeatRunLink, null);
  }

  setSelectedRun(selectedRun: Run): Observable<any> {
    if (!selectedRun._links?.select) {
      throw new Error('No select link found on run');
    }

    return this.http.put<any>(selectedRun._links.select.href, null);
  }

  getCellLineAssays(): Assay[] {
    // "Cell line assays" is a misnomer. The UX should display:
    // All cell line assays plus WBC assay (first) and NRBC (last)
    // Note: WBC and NRBC do not have associated cell line absolutes
    const wbcAssay = this.appStateService.accession.assays.find(
      (assay) => assay.standardIdexxAssay === this.workspaceConfigService.wbcAssayId
    );
    const nrbcAssay = this.appStateService.accession.assays.find(
      (assay) => assay.standardIdexxAssay === this.workspaceConfigService.nrbcAssayId
    );
    const cellLineAssays = this.appStateService.accession.assays.filter((assay) =>
      this.getCellLineAssociationId(assay)
    );

    if (wbcAssay) {
      cellLineAssays.splice(0, 0, wbcAssay);
    }

    if (nrbcAssay) {
      cellLineAssays.push(nrbcAssay);
    }

    return cellLineAssays.map((assay) => {
      assay.category = AssayCategory.CELL_LINE;
      return assay;
    });
  }

  getNonCellLineAssays(): Assay[] {
    return this.appStateService.accession.assays.filter((assay) => {
      const hasCellLineAssociation = this.hasCellLineAssociation(assay);
      const isCellLineAbsolute = this.isCellLineAbsolute(assay.standardIdexxAssay);
      const isObservationAssay = this.isObservationAssay(assay);
      const isWBCAssay = assay.standardIdexxAssay === this.workspaceConfigService.wbcAssayId;
      const isNRBCAssay = assay.standardIdexxAssay === this.workspaceConfigService.nrbcAssayId;

      if (!hasCellLineAssociation && !isCellLineAbsolute && !isObservationAssay && !isWBCAssay && !isNRBCAssay) {
        assay.category = AssayCategory.NON_CELL_LINE;
        return assay;
      }
    });
  }

  getObservationAssays() {
    const observationAssays = this.appStateService.accession.assays.filter((assay) => this.isObservationAssay(assay));
    return observationAssays.map((assay) => {
      assay.category = AssayCategory.OBSERVATION;
      return assay;
    });
  }

  private hasCellLineAssociation(assay: Assay): boolean {
    return !!this.getCellLineAssociationId(assay);
  }

  getCellLineAssociationId(assay: Assay): string {
    return this.workspaceConfigService.cellLineAssociations[assay.standardIdexxAssay];
  }

  private isCellLineAbsolute(id: string): boolean {
    return Object.values(this.workspaceConfigService.cellLineAssociations).indexOf(id) > -1;
  }

  isObservationAssay(assay: Assay) {
    return assay.resultDefinition.valueType !== 'NUMERIC';
  }
}
