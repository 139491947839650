import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CBCAccession, WorkspaceAccessionService } from '../workspace-accession.service';
import { AppStateService } from '../../app-state.service';
import { WorkspaceQueueService } from '../workspace-queue.service';
import { AccessionService } from '../accession/accession.service';

@Injectable({ providedIn: 'root' })
export class QueueAccessionResolverService {
  constructor(
    private appStateService: AppStateService,
    private assayService: AccessionService,
    private accessionService: WorkspaceAccessionService,
    private workQueuesService: WorkspaceQueueService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CBCAccession> {
    // match the current workspace to an item in the workspace queue list
    const workQueue = this.appStateService.workQueues.find(
      (wq) => wq.workspaceId === this.appStateService.currentWorkspace.id
    );

    if (workQueue) {
      return this.workQueuesService.advanceQueue(workQueue._links.first);
    } else {
      return of(null);
    }
  }
}
