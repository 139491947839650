import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WorkspaceComponent } from './workspace/workspace.component';
import { AccessionComponent } from './accession/accession.component';
import { RouterModule } from '@angular/router';
import { WorkspaceAccessionService } from './workspace-accession.service';
import { AssayCardComponent } from './assay/assay-card/assay-card.component';
import { AssayLineItemComponent } from './assay/assay-line-item/assay-line-item.component';
import {
  ApplicationPipesModule,
  LabNotesComponent,
  LUX,
  LuxLayoutModule,
  SamplesModule,
  SnackbarModule,
  UtilModule,
  WorkspacesModule,
} from '@lims-common-ux/lux';
import { AccessionService } from './accession/accession.service';
import { TranslateModule } from '@ngx-translate/core';

// tslint:disable-next-line:max-line-length
import { SemiQuantitativeResultInputComponent } from './assay/semi-quantitative-result-input/semi-quantitative-result-input.component';
import { SemiQuantitativeValueDirective } from './assay/semi-quantitative-result-input/semi-quantitative-value.directive';
import { NumericResultInputComponent } from './assay/numeric-result-input/numeric-result-input.component';
import { NumericValueValidatorDirective } from './assay/numeric-result-input/numeric-value-validator.directive';
import { AssayDetailsComponent } from './assay/assay-details/assay-details.component';
// tslint:disable-next-line:max-line-length
import { SemiQuantitativeResultComboComponent } from './assay/semi-quantitative-result-combo/semi-quantitative-result-combo.component';
import { DefinedMultiTextComponent } from './assay/defined-multi-text/defined-multi-text.component';
import { WorkspaceNavigationComponent } from './workspace/workspace-navigation/workspace-navigation.component';
import { PanelModule } from '../panel/panel.module';
import { DetailsKeyComponent } from './assay/assay-details/details-key/details-key.component';
import { FreeTextComponent } from './assay/free-text/free-text.component';
import { RunPickerComponent } from './run-picker/run-picker.component';
import { ManualDiffModule } from './manual-diff/manual-diff.module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RunResultComponent } from './run-result/run-result.component';
import { RepeatRunComponent } from './repeat-run/repeat-run.component';
import { CurrentRunPipe } from './current-run.pipe';
import { EmptyQueueComponent } from './empty-queue/empty-queue.component';
import { AssayCommentsComponent } from './assay/assay-comments/assay-comments.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    WorkspaceComponent,
    AccessionComponent,
    AssayCardComponent,
    AssayLineItemComponent,
    SemiQuantitativeResultInputComponent,
    SemiQuantitativeValueDirective,
    NumericResultInputComponent,
    NumericValueValidatorDirective,
    AssayDetailsComponent,
    SemiQuantitativeResultComboComponent,
    DefinedMultiTextComponent,
    WorkspaceNavigationComponent,
    DetailsKeyComponent,
    FreeTextComponent,
    RunPickerComponent,
    ToolbarComponent,
    RunResultComponent,
    RepeatRunComponent,
    CurrentRunPipe,
    EmptyQueueComponent,
    AssayCommentsComponent,
  ],
  imports: [
    TranslateModule.forChild({}),
    FormsModule,
    CommonModule,
    RouterModule,
    LuxLayoutModule,
    LUX,
    ApplicationPipesModule,
    SnackbarModule,
    PanelModule,
    SamplesModule,
    UtilModule,
    ManualDiffModule,
    SharedModule,
    WorkspacesModule,
    LabNotesComponent,
  ],
  providers: [WorkspaceAccessionService, AccessionService, CurrentRunPipe],
  exports: [WorkspaceNavigationComponent],
})
export class WorkspaceModule {}
