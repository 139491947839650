import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {
  AccessionModule,
  DataResourcesModule,
  FlyoutModule,
  GlobalErrorHandlerModule,
  GlobalErrorHandlerService,
  LabRequestInterceptor,
  LUX,
  SnackbarModule,
  FeaturesModule,
  LuxLayoutModule,
} from '@lims-common-ux/lux';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { AppStateService } from './app-state.service';
import { AppRoutingModule } from './app-routing.module';
import { LabModule } from './lab/lab.module';
import { WorkspaceModule } from './workspace/workspace.module';
import { CBCLabService } from './lab.service';
import { CanDeactivateGuard } from './can-deactivate/can-deactivate.guard';
import { ApplicationInitService } from './application-init.service';
import { StaticAppData } from './interfaces/application-data.interface';
import { InteractionStatus, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {
  MsalBroadcastService,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import { Observable, filter, switchMap, take } from 'rxjs';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

declare let CLIENT_ID: string;
declare let TENANT_ID: string;
declare let PROTECTED_RESOURCES: [string, string[]][];

function securityInitializer(broadcaster: MsalBroadcastService, service: MsalService): () => Observable<any> {
  return () => {
    // this is required to the service to complete its initialization. We need this to be done in an app initializer
    // because we often have data we need to retrieve that is protected, and this will take care of making sure those
    // calls are properly authenticated.
    service.handleRedirectObservable().subscribe(() => {
      // we don't care about the result value here, just let the library do its thing
    });

    return broadcaster.inProgress$.pipe(
      filter((progress) => progress === InteractionStatus.None),
      take(1) // the observable never completes normally, and we only need this to be hit once
    );
  };
}

// Sample of retrieving a protected resource during app initialization.
function protectedResourceInitializer(broadcaster: MsalBroadcastService, http: HttpClient): () => Observable<any> {
  return () => {
    return broadcaster.inProgress$.pipe(
      // this is required here to make sure the http interceptors are ready before we make any calls. The securityInit
      // function is responsible for getting the Msal library ready.
      filter((progress) => progress === InteractionStatus.None),
      switchMap(() => {
        return http.get<string>('/index');
      }),
      take(1) // the observable never completes normally, and we only need this to be hit once
    );
  };
}

const PROTECTED = new Map<string, Array<string>>(PROTECTED_RESOURCES);

function applicationDataInitFactory(service: ApplicationInitService): () => Promise<StaticAppData> {
  return (): Promise<StaticAppData> => service.initialize();
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent, MsalRedirectComponent],
  imports: [
    BrowserModule,
    LuxLayoutModule,
    DataResourcesModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: CLIENT_ID, // Application (client) ID from the app registration
          authority: 'https://login.microsoftonline.com/' + TENANT_ID + '/',
          redirectUri: '/',
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: false,
        },
      }),
      {
        interactionType: InteractionType.Redirect,
      },
      {
        interactionType: InteractionType.Redirect,
        // The api url is currently, not super specific. The backend is simply configured to verify the access tokens it
        // receives are valid. We currently don't have a specific use for different roles and access rules, so this could
        // be any endpoint configured in Azure for the application at the moment. MSAL Simply needs a value here in order
        // for interceptors to work correctly and add auth headers to all the required resources.
        protectedResourceMap: new Map(PROTECTED),
      }
    ),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    LUX,
    FlyoutModule,
    AccessionModule,
    WorkspaceModule,
    LabModule,
    AppRoutingModule,
    RouterModule.forRoot([], {
      onSameUrlNavigation: 'reload',
      useHash: true,
    }),
    SnackbarModule,
    GlobalErrorHandlerModule,
    FeaturesModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: securityInitializer,
      deps: [MsalBroadcastService, MsalService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: applicationDataInitFactory,
      deps: [ApplicationInitService],
      multi: true,
    },
    AppStateService,
    CBCLabService,
    CanDeactivateGuard,
    TranslatePipe,
    { provide: 'Window', useValue: window },
    { provide: 'Document', useValue: document },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LabRequestInterceptor,
      multi: true,
    },
    GlobalErrorHandlerService,
    {
      provide: ErrorHandler,
      useExisting: GlobalErrorHandlerService,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
