import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-numeric-result-input',
  templateUrl: './numeric-result-input.component.html',
  styleUrls: ['./numeric-result-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericResultInputComponent),
      multi: true,
    },
  ],
})
export class NumericResultInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('input', { static: false }) input: ElementRef;

  @ViewChild('numericResultInputWrapper', { static: false })
  cmpWrapper: ElementRef;

  @Input()
  val = '';

  @Input()
  transformedValue = '';

  @Input()
  disabled = false;

  @Output()
  resultChanged = new EventEmitter<true>();

  @Input()
  noResult: boolean;

  @Input()
  emptyResult: boolean;

  @Input()
  repeatRequested: boolean;

  @Output()
  noResultChange = new EventEmitter<boolean>();

  @Output()
  emptyResultChange = new EventEmitter<boolean>();

  statusSub: Subscription;
  valueSub: Subscription;
  clearInitialResult;

  control: AbstractControl;
  controlStatus: string;

  onChange: any = () => {
    // empty on purpose
  };

  onTouched: any = () => {
    // empty on purpose
  };

  get value() {
    return this.val;
  }

  set value(val: string) {
    this.val = this.removeTrailingDecimalSeparators(val);

    // If there's a value entered, then toggle noResult presentation to off
    if (this.val) {
      this.noResult = false;
      this.emptyResult = false; // Empty result should be set to false to prevent validation bypass
    }

    // In any case emit noResult for form level effects
    this.noResultChange.emit(this.noResult);
    this.emptyResultChange.emit(this.emptyResult);
  }

  constructor(private injector: Injector) {}

  validate() {
    return true;
  }

  ngOnInit() {
    const model = this.injector.get(NgControl);
    this.control = model.control;

    this.valueSub = this.control.valueChanges.subscribe((val) => {
      if (this.clearInitialResult === undefined) {
        if (val) {
          this.clearInitialResult = true;
        } else {
          this.clearInitialResult = false;
        }
      }
      if (this.repeatRequested || this.noResult) {
        this.control?.setErrors(null);
      }
    });

    this.statusSub = this.control.statusChanges.subscribe((status: string) => {
      this.controlStatus = status;
    });
  }

  ngOnDestroy(): void {
    if (this.statusSub) {
      this.statusSub.unsubscribe();
    }

    if (this.valueSub) {
      this.valueSub.unsubscribe();
    }
  }

  writeValue(value: string) {
    if (value !== this.value) {
      this.value = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouch: any) {
    this.onTouched = () => {
      onTouch();
    };
  }

  // visible for testing
  setErrorState() {
    const isInvalid =
      this.control?.dirty &&
      (this.value === null || this.value === '') &&
      !this.noResult &&
      !this.emptyResult &&
      !this.repeatRequested;

    if (isInvalid) {
      const errors = { inputError: true };
      this.control?.setErrors(errors);
    } else {
      this.control?.setErrors(null);
    }
  }

  focusInput() {
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 0);
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  handleInput($event) {
    this.clearInitialResult = false;
    this.writeValue($event.target.value);
    this.control.markAsDirty();
    this.fireIfDirty();
  }

  handleBackspace($event) {
    if (this.clearInitialResult) {
      if (this.value) {
        $event.target.value = '';
      }
      this.clearInitialResult = false;
    }
    this.handleInput($event);
  }

  handleFocusOut($event) {
    this.onTouched($event);
    if (document.activeElement === document.body || !this.cmpWrapper.nativeElement.contains(document.activeElement)) {
      this.input.nativeElement.value = this.removeTrailingDecimalSeparators(this.value);
    }
    this.fireIfDirty();
  }

  handleEnter($event) {
    if (this.value) {
      this.input.nativeElement.value = this.removeTrailingDecimalSeparators(this.value);
      if (this.input.nativeElement.value) {
        // This should force a blur event which will update the value.
        this.resultChanged.emit(true);
      }
    }
  }

  private removeTrailingDecimalSeparators(val: string) {
    if (val) {
      const lastCharacter = val[val.length - 1];

      if (val.length > 1 && (lastCharacter === ',' || lastCharacter === '.')) {
        val = val.substring(0, val.length - 1);
      }
    }

    return val;
  }

  private fireIfDirty() {
    if (this.control.dirty && !this.noResult && !this.repeatRequested) {
      this.setErrorState();
      this.onChange(this.value);
    }
  }
}
