import { Injectable } from '@angular/core';

import { WorkspaceConfigService } from '../workspace/workspace-config.service';
import { Observable, of, switchMap, tap } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { ManualDiffAssay } from './manual-diff.model';
import { CBCAccession, WorkspaceAccessionService } from '../workspace-accession.service';
import { AppStateService } from '../../app-state.service';

export interface ManualDiffCount {
  standardIdexxAssay: string;
  value: string;
}

export interface ManualDiffSaveCount {
  assay: string;
  count: number;
}

@Injectable({
  providedIn: 'root',
})
export class ManualDiffService {
  manualDiffCounts: ManualDiffCount[];

  constructor(
    private configService: WorkspaceConfigService,
    private http: HttpClient,
    private workspaceAccessionService: WorkspaceAccessionService,
    private appStateService: AppStateService
  ) {}

  calculateManualDiffCounts(assays: ManualDiffAssay[]): Observable<ManualDiffCount[]> {
    this.manualDiffCounts = [];
    const assaysWithCounts = assays.filter((assay) => assay.count > 0);

    if (
      assaysWithCounts.length < 1 ||
      (assaysWithCounts.length === 1 && assaysWithCounts[0].standardIdexxAssay === this.configService.nrbcAssayId)
    ) {
      return of([]);
    }

    let params = new HttpParams();

    assays.forEach((assay) => {
      params = params.set(assay.standardIdexxAssay, assay.count.toString());
    });

    return this.http.get<ManualDiffCount[]>(this.configService.manualDiffCountsLink.href, { params }).pipe(
      tap((response: ManualDiffCount[]) => {
        this.manualDiffCounts = response;
      })
    );
  }

  addManualDiff(savableCounts: ManualDiffSaveCount[]): Observable<CBCAccession> {
    return this.http.post<ManualDiffSaveCount[]>(this.configService.addManualDiffLink.href, savableCounts).pipe(
      switchMap(() => {
        // Update accession
        return this.workspaceAccessionService.loadWorkspaceAccession(
          this.appStateService.accessionHeader,
          this.appStateService.currentWorkspace
        );
      })
    );
  }
}
