import { Comment, DefinedTextValue, Link, ShortCodeMapping } from '@lims-common-ux/lux';

export enum AssayStatus {
  CANCELED = 'CANCELED',
  OPENED = 'OPENED',
  RESULT_RECEIVED = 'RESULT_RECEIVED',
  TECHNICIAN_REVIEW = 'TECHNICIAN_REVIEW',
  TECHNICALLY_ACCEPTED = 'TECHNICALLY_ACCEPTED',
  RELEASED = 'RELEASED',
  REPEAT_REQUESTED = 'REPEAT_REQUESTED',
}

export interface Instrument {
  name: string;
}

export interface Flag {
  code: string;
  message: string;
}

export interface Result {
  value: any;
  relevanceIndicator?: string;
  interval?: ResultInterval;
  enteredBy: string;
  timestamp: string;
  instrument?: Instrument;
  transformedValue?: string;
  flags?: Flag[];
}

export interface Assay {
  attributes: any[];
  clinicalReference: string;
  category?: number;
  comments: Comment[];
  expectedPerformingLab: string;
  hasChanges: boolean;
  name: string;
  operationalId: any;
  presentationStatus: string;
  presentationValue: any;
  repeatRequested: boolean;
  unitOfMeasure: string;
  result: Result;
  canModify: boolean;
  resultDefinition: {
    valueType: string;
    types?: DefinedTextValue[] | string[];
    intervals?: ResultInterval[];
    valueCombinations?: any;
  };
  resultRequired: ResultRequired;
  shortCodes?: ShortCodeMapping;
  status: AssayStatus;
  standardIdexxAssay: string;
  testCode: string;
  // This is purely a UI construct. Used to keep updates separate from current values
  updatedResult: {
    // since result values can take various forms we are using 'any' here
    value: any;
    // noResult is an actual type in `value` but because we use the value for things like form controls we can't share
    // that for this
    noResult: boolean;
    emptyResult: boolean;
  };
  version: number;
  _links: {
    self: Link;
  };
}

export enum ResultRequired {
  ESSENTIAL = 'ESSENTIAL',
  NON_ESSENTIAL = 'NON_ESSENTIAL',
}

export interface ResultInterval {
  low: number;
  high?: number;
  customerFacingText: string;
}
