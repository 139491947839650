<div *ngIf="visible" data-cy="workspace-landing-wrapper" luxFlex luxLayout="column">
  <div data-cy="workspace-landing-header" id="workspace-landing-header" class="spot-typography__heading--level-2">
    {{ workspace.name }}
  </div>
  <div data-cy="workspace-landing-content" id="workspace-landing-content">
    <span data-cy="workspace-queue-empty-message" id="workspace-queue-empty-message">{{
      'ERRORS_AND_FEEDBACK.QUEUE_EMPTY' | translate
    }}</span>
  </div>
</div>
