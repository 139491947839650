<div
  #assayWrapper
  *ngIf="assay"
  data-cy="assay-wrapper"
  class="assay-component-wrapper line-item"
  luxLayout="row"
  luxLayoutAlign="start start"
  style="position: relative"
  [ngClass]="[presentationClass, selected ? 'SELECTED' : '']"
  (focusin)="handleFocusIn()"
  (mousedown)="handleClick($event)">
  <app-assay-comments
    [name]="assay.testCode + '_COMMENTS'"
    [(ngModel)]="assay.comments"
    [commentCount]="assay.comments?.length">
  </app-assay-comments>
  <!-- INDICATORS (E.G. SELECTED, RELEASED) -->
  <div luxLayout="column" class="assay-indicators">
    <div class="assay-indicator assay-indicator-selected">
      <span>
        <svg class="spot-icon assay-selected-icon" data-cy="assay-selected-icon" aria-labelledby="title">
          <title>{{ 'LABELS.SELECTED' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#show"></use>
        </svg>
      </span>
    </div>
    <div class="assay-indicator assay-indicator-has-changes">
      <span>
        <svg
          [attr.data-cy]="assay.testCode + '-has-changes-indicator'"
          aria-labelledby="title"
          class="spot-icon assay-has-changes-icon"
          data-cy="assay-has-changes-icon">
          <title>{{ 'LABELS.HAS_CHANGES' | translate }}</title>
          <use xlink:href="../../assets/icons/spot_icons.svg#save"></use>
        </svg>
      </span>
    </div>
  </div>
  <!-- /ASSAY INDICATORS -->

  <!-- ASSAY WRAPPER -->
  <div class="assay-wrapper" luxFlex="98%" data-cy="assay-wrapper" [attr.data-cy-assay]="assay.testCode">
    <div luxLayout="row" class="assay" data-cy="assay">
      <!-- ASSAY TESTCODE -->
      <div class="assay-test-code-wrapper" luxLayout="row">
        <div
          class="assay-test-code spot-typography__heading--level-6"
          luxFlex="130px"
          data-cy="assay-name"
          luxLayoutAlign="start center">
          <div class="assay-symbol assay-symbol-released">
            <!-- released assays render a star -->
            <span>
              <svg
                [attr.data-cy]="assay.testCode + '-released-indicator'"
                class="spot-icon assay-released-icon"
                aria-labelledby="title">
                <title>{{ 'LABELS.RELEASED' | translate }}</title>
                <use xlink:href="../../assets/icons/spot_icons.svg#favorite-featured"></use>
              </svg>
            </span>
          </div>
          {{ assay.testCode }}
        </div>
      </div>
      <!-- /ASSAY TESTCODE -->

      <!-- ASSAY BODY (CONTAINS INPUTS) -->
      <div luxFlex="80px" class="assay-body" luxLayoutAlign="center center">
        <app-numeric-result-input
          #resultInput
          luxFlex
          appNumericValueValidator
          [disabled]="!assay.canModify"
          [name]="assay.testCode"
          [transformedValue]="assay.result?.transformedValue"
          [(ngModel)]="assay.updatedResult.value"
          [(noResult)]="assay.updatedResult.noResult"
          [(emptyResult)]="assay.updatedResult.emptyResult"
          [repeatRequested]="assay.repeatRequested"
          (resultChanged)="focusNext($event)">
        </app-numeric-result-input>
      </div>
      <!-- /ASSAY BODY -->

      <span luxFlex="35px" luxLayoutAlign="start center">
        <span
          *ngIf="assay.result?.value && !assay.result?.value.emptyResult"
          class="unit-of-measure"
          data-cy="line-item-unit-of-measure"
          >{{ assay.unitOfMeasure }}</span
        >
      </span>

      <!-- ABSOLUTE RESULT -->
      <div class="absolute-result-wrapper" luxFlex="60px" luxLayoutAlign="end center">
        <span
          class="absolute-result"
          data-cy="absolute-result"
          *ngIf="absoluteAssay?.result?.value && !absoluteAssay.result?.value.noResult"
          >{{ absoluteAssay.result.value }}</span
        >
      </div>
      <!-- /ABSOLUTE RESULT -->

      <!-- PERFORMING LAB ID (TRANSFERS) -->
      <div
        *ngIf="
          assay.status === 'OPENED' && lab.id !== assay.expectedPerformingLab && assay?.updatedResult.value === null
        "
        class="assay-body-background"
        luxLayout="column"
        luxLayoutAlign="center">
        <span data-cy="expected-performing-lab">{{ assay.expectedPerformingLab }}</span>
      </div>
      <!-- /PERFORMING LAB ID -->

      <!-- CLINICAL REFERENCE -->
      <div
        luxFlex="150px"
        class="assay-clinical-reference-wrapper"
        luxLayout="row"
        luxLayoutAlign="end center"
        style="padding-top: 5px">
        <lux-clinical-reference
          *ngIf="showClinicalReference && !absoluteAssay"
          data-cy="non-cell-line-reference-range"
          luxFill
          [indicator]="assay.result?.relevanceIndicator"
          [clinicalReference]="assay.clinicalReference"></lux-clinical-reference>
        <lux-clinical-reference
          *ngIf="showClinicalReference && absoluteAssay"
          data-cy="absolute-reference-range"
          class="absolute-reference-range"
          luxFill
          [indicator]="absoluteAssay.result?.relevanceIndicator"
          [clinicalReference]="absoluteAssay.clinicalReference"></lux-clinical-reference>
      </div>
      <!-- /CLINICAL REFERENCE -->

      <!-- ASSAY INDICATORS 120 -->
      <div luxLayout="row" luxLayoutAlign="end center" luxFlex>
        <div class="assay-symbol assay-symbol-canceled">
          <svg
            [attr.data-cy]="assay.testCode + '-canceled-indicator'"
            aria-labelledby="title"
            class="spot-icon assay-canceled-icon"
            data-cy="assay-canceled-icon">
            <title>{{ 'LABELS.CANCELED' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#cancel"></use>
          </svg>
        </div>

        <div class="assay-symbol assay-symbol-technician-review">
          <svg
            [attr.data-cy]="assay.testCode + '-alert-indicator'"
            class="spot-icon assay-alert-icon"
            data-cy="assay-alert-icon"
            aria-labelledby="title">
            <title>{{ 'LABELS.TECHNICIAN_REVIEW' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#alert-notification"></use>
          </svg>
        </div>

        <div class="assay-symbol assay-symbol-has-flags" data-cy="assay-has-flags">
          <svg class="spot-icon assay-flag-icon" data-cy="assay-instrument-flag" aria-labelledby="title">
            <title>{{ 'LABELS.HAS_FLAGS' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#flag"></use>
          </svg>
        </div>

        <div class="assay-symbol assay-symbol-has-transformed-value" data-cy="assay-has-transformed-value-icon">
          <svg
            [attr.data-cy]="assay.testCode + '-has-transformed-value-indicator'"
            aria-labelledby="title"
            class="spot-icon assay-has-transformed-value-icon">
            <title>{{ assay?.result?.transformedValue }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#info"></use>
          </svg>
        </div>

        <div class="assay-symbol assay-symbol-has-comments" data-cy="assay-has-comments">
          <svg
            [attr.data-cy]="assay.testCode + '-has-comments-indicator'"
            class="spot-icon assay-comment-icon"
            aria-labelledby="title">
            <title>{{ 'LABELS.HAS_COMMENTS' | translate }}</title>
            <use xlink:href="../../assets/icons/spot_icons.svg#communicate"></use>
          </svg>
        </div>
      </div>
      <!-- /ASSAY INDICATORS -->
    </div>
  </div>
  <!-- /ASSAY WRAPPER -->
</div>
