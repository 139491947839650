import { Pipe, PipeTransform } from '@angular/core';
import { Panel } from './panel.interface';

@Pipe({
  name: 'acceptable',
})
export class AcceptablePipe implements PipeTransform {
  transform(value: Panel[]): Panel[] {
    if (!value) {
      return [];
    }

    return value.filter((panel) => panel.acceptable);
  }
}
