import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LabComponent } from './lab.component';
import { FlyoutModule, KeyboardHelpCategoryModule, LUX, LuxLayoutModule } from '@lims-common-ux/lux';
import { TranslateModule } from '@ngx-translate/core';
import { HelpModalComponent } from './help-modal/help-modal.component';

@NgModule({
  declarations: [LabComponent, HelpModalComponent],
  imports: [
    CommonModule,
    RouterModule,
    LUX,
    LuxLayoutModule,
    FlyoutModule,
    TranslateModule,
    KeyboardHelpCategoryModule,
  ],
})
export class LabModule {}
