<ng-container *ngIf="assay && assay.resultDefinition">
  <div data-cy="observation-listing" class="observation-listing">
    <div luxLayout="column" *ngFor="let value of assay.resultDefinition.types">
      <div data-cy="observation" class="observation" luxLayout="row" luxLayoutAlign="space-between start">
        <span luxFlex="60px" class="shortcode">{{ shortCodeFor(value) }}</span>
        <span luxFlex>{{ getObservationDisplayTextByValue(value.code) }}</span>
      </div>
    </div>
  </div>
</ng-container>
