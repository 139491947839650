import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AppStateService } from '../../../app-state.service';
import { Assay } from '../../../interfaces/assay.interface';
import { Subscription } from 'rxjs';
import { Comment, CommentsComponent, Lab, Workspace } from '@lims-common-ux/lux';
import { AccessionService } from '../../accession/accession.service';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Panel } from '../../../panel/panel.interface';
import { PanelComponent } from '../../../panel/panel/panel.component';
import { AssayCardComponent } from '../assay-card/assay-card.component';
import { CBCAccession } from '../../workspace-accession.service';
import { PanelAcceptComponent } from '../../../panel/panel-accept/panel-accept.component';
import { WorkspaceConfigService } from '../../workspace/workspace-config.service';

@Component({
  selector: 'app-assay-details',
  templateUrl: './assay-details.component.html',
  styleUrls: ['./assay-details.component.scss'],
})
export class AssayDetailsComponent implements OnInit, OnDestroy {
  @Input()
  headerAccession: Accession;

  @Input()
  workspaceAccession: CBCAccession;

  @Input()
  selectedAssayCard: AssayCardComponent;

  @Input()
  assayCardHasChanges: boolean;

  @Input()
  panels: Panel[] = [];

  @Input()
  lab: Lab;

  @Output()
  markAsNoResult = new EventEmitter();

  @Output()
  focusOut: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  selectedAssayUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  panelCommentsUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  panelAcceptUpdated: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('assayDetails', { static: false })
  assayDetailWrapper: ElementRef;

  @ViewChild('resultComment', { static: false })
  resultComment!: CommentsComponent;

  @ViewChild('panelsView', { static: false })
  panelsView!: PanelComponent;

  @ViewChild('panelAccept', { static: false })
  panelAccept!: PanelAcceptComponent;

  selectedAssaySub: Subscription;

  selectedAssay: Assay;

  assayCommentsDataSourceSub: Subscription;

  assayCommentsDataSource: string;

  resultWorkspace: Workspace;

  acceptPanelsEnabled: boolean;

  constructor(
    private assayService: AccessionService,
    private appStateService: AppStateService,
    private configService: WorkspaceConfigService
  ) {}

  ngOnInit(): void {
    this.acceptPanelsEnabled = this.configService.acceptPanelsEnabled;

    this.selectedAssay = this.selectedAssayCard?.assay;

    this.selectedAssaySub = this.appStateService.currentAssaySub.subscribe((assayWrapper) => {
      if (assayWrapper) {
        if (this.resultComment) {
          this.resultComment.resetComments();
        }

        this.selectedAssay = assayWrapper;
      } else if (assayWrapper === null) {
        // when the selectedAssay is null Workspace Details will display
        if (this.assayDetailWrapper && !this.assayDetailWrapper.nativeElement.contains(document.activeElement)) {
          this.selectedAssay = assayWrapper;
        }
      }
      this.resultWorkspace = this.appStateService.currentWorkspace;
    });

    this.assayCommentsDataSource = this.appStateService.commentsDataSource.href;
  }

  ngOnDestroy() {
    if (this.selectedAssaySub) {
      this.selectedAssaySub.unsubscribe();
    }

    if (this.assayCommentsDataSourceSub) {
      this.assayCommentsDataSourceSub.unsubscribe();
    }
  }

  noResult($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.markAsNoResult.emit();
  }

  onAddComment(comment: Comment) {
    if (comment) {
      this.selectedAssay.comments = this.selectedAssay.comments ? this.selectedAssay.comments : [];

      // Disallow duplicate comments
      const hasComment =
        this.selectedAssay.comments &&
        this.selectedAssay.comments.filter((existingComment: Comment) => {
          return existingComment.id === comment.id;
        });
      if (this.selectedAssay.comments && !hasComment.length) {
        this.selectedAssay.comments.splice(0, 0, comment);

        window.requestAnimationFrame(() => {
          this.selectedAssayUpdated.emit(true);
        });
      }

      // Close search results and return focus to search input
      this.resultComment.resetComments();
      this.focusOut.emit();
    }
  }

  onRemoveComment(comment: Comment) {
    this.selectedAssay.comments = this.selectedAssay.comments.filter((existingComment: Comment) => {
      return existingComment.id !== comment.id;
    });

    this.selectedAssayUpdated.emit(true);

    this.focusOut.emit();
  }

  focusResultComment() {
    setTimeout(() => {
      this.resultComment.focusSearchInput();
    }, 0);
  }

  isArray(value: any[]): boolean {
    return Array.isArray(value);
  }

  onPanelCommentsUpdate(panel: Panel): void {
    window.requestAnimationFrame(() => {
      this.panelCommentsUpdated.emit(true);
    });
  }

  onPanelAcceptUpdate(): void {
    this.panelAcceptUpdated.emit();
  }

  getAssayResultEnteredByDisplayName(): string {
    if (this.selectedAssay.result.instrument) {
      return this.selectedAssay.result.instrument.name;
    } else {
      return this.selectedAssay.result.enteredBy;
    }
  }
}
