import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { DefinedTextResultComponent, DefinedTextValue, Lab } from '@lims-common-ux/lux';

import { AppStateService } from '../../../app-state.service';
import { Assay, AssayStatus } from '../../../interfaces/assay.interface';
import { AssayWrapperService } from '../assay-wrapper.service';
import { FreeTextComponent } from '../free-text/free-text.component';
import { SemiQuantitativeResultComboComponent } from '../semi-quantitative-result-combo/semi-quantitative-result-combo.component';
import { SemiQuantitativeResultInputComponent } from '../semi-quantitative-result-input/semi-quantitative-result-input.component';
import { DefinedMultiTextComponent } from '../defined-multi-text/defined-multi-text.component';

@Component({
  selector: 'app-assay-card',
  templateUrl: './assay-card.component.html',
  styleUrls: ['./assay-card.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class AssayCardComponent implements AfterViewInit {
  @ViewChild('cardBody', { static: false })
  body: ElementRef;

  @ViewChild('assayWrapper', { static: false })
  assayWrapper: ElementRef;

  @ViewChild('displayValueLink', { static: false })
  displayValueLink: ElementRef;

  @ViewChild('resultInput', { static: false })
  resultInput:
    | DefinedTextResultComponent
    | DefinedMultiTextComponent
    | SemiQuantitativeResultComboComponent
    | SemiQuantitativeResultInputComponent
    | FreeTextComponent;

  @Input()
  assay: Assay;

  @Input()
  firstCard: boolean;

  @Input()
  lastCard: boolean;

  @Input()
  lab: Lab;

  @Output()
  valueChange = new EventEmitter<true>();

  presentationClass = '';

  showClinicalReference: boolean;

  editMode = false;

  constructor(
    private appStateService: AppStateService,
    private assayWrapperService: AssayWrapperService
  ) {}

  ngAfterViewInit() {
    // Different result inputs may transform entered values, here we are setting a presentation value for use
    // in assay details and other areas of the app where we need access to the transformed result input value on load
    if (this.resultInput && this.resultInput.input.nativeElement) {
      let updatedValue = this.assay?.result?.value;

      if (this.assay && this.assay.presentationValue !== updatedValue) {
        // semi-quant combo, and defined text multi will have an array of values
        if (Array.isArray(updatedValue)) {
          updatedValue = [...updatedValue];
          updatedValue.forEach((resultValue, index) => {
            let currentVal;
            if (typeof resultValue === 'string') {
              // defined text multi
              currentVal = resultValue;
              // None-Seen indicators, want to treat these differently than actual selected values
            } else if (resultValue.typeCode === '' || resultValue.typeCode === '0' || resultValue.typeCode === 0) {
              currentVal = resultValue.typeCode;
            } else {
              // semi-quant combo
              currentVal = resultValue;
            }

            if (this.assay.resultDefinition.types) {
              let i = 0;
              // look up the translated values from the supplied result definitions.
              for (i; i < this.assay?.resultDefinition?.types?.length; i += 1) {
                const possibleValue = this.assay?.resultDefinition?.types[i];
                if (
                  typeof possibleValue !== 'string' &&
                  (possibleValue?.code === currentVal || possibleValue?.code === currentVal?.typeCode)
                ) {
                  if (currentVal.interval) {
                    updatedValue[index] = Object.assign({ display: possibleValue?.display }, currentVal);
                  } else {
                    if (!currentVal?.isNoneSeen) {
                      updatedValue[index] = {
                        display: possibleValue?.display,
                        noneSeen: false,
                        code: possibleValue?.code,
                      };
                    } else {
                      updatedValue[index] = {
                        display: possibleValue?.display,
                        noneSeen: true,
                        code: possibleValue?.code,
                      };
                    }
                  }
                  break;
                }
              }
            }
          });
        } else if (this.assay?.result?.value) {
          if (this.assay.result?.interval) {
            // Semi quantitative result
            updatedValue = this.assay.result.interval.customerFacingText;
          } else if (this.assay.resultDefinition.types) {
            // Defined text result
            let i = 0;
            for (i; i < this.assay?.resultDefinition?.types?.length; i += 1) {
              const possibleValue: DefinedTextValue = this.assay?.resultDefinition?.types[i] as DefinedTextValue;

              if (updatedValue === possibleValue?.code) {
                updatedValue = possibleValue.display;
              }
            }
          }
        }

        this.assay.presentationValue = updatedValue;
      }

      this.resultInput.control?.valueChanges.subscribe((newValue) => {
        if (newValue) {
          // we need to unset this in case some clicks "repeat", then goes back and adds a result value.
          this.assay.repeatRequested = false;
        }
        this.setCardPresentation();
      });
    }
  }

  /* EVALUATE AN ASSAY CARD ASSAY STATUS, RESULT, UPDATED RESULT, AND THE ASSAY CARD RESULT INPUT CONTROL */
  /* TO DETERMINE CARD WRAPPER PRESENTATION. THE PRESENTATION CLASS STRING IS DIRECTLY TIED TO CSS STYLE */
  /* DEFINITIONS. ASSIGNING CSS CLASSES THIS WAY CAN BE VERY FAST AND AVOIDS COMPLICATIONS WITH ANGULAR */
  /* CHANGE DETECTION AND SCREEN PAINTS WITHOUT RELYING ON AN OBSERVABLE. */
  /* NOTE: THERE IS NO EXPLICIT 'SELECTED' STATE. SELECTED STYLE PRESENTATION IS A FUNCTION */

  /* OF FOCUS-WITHIN AND APPSTATE CURRENTASSAY */
  getCardWrapperPresentation() {
    const classes = [];

    if (this.assay) {
      // performed-at-different-lab
      if (
        this.assay.status === 'OPENED' &&
        this.lab.id !== this.assay.expectedPerformingLab &&
        this.assay?.updatedResult.value === null
      ) {
        classes.push('performed-at-different-lab');
      }

      if (this.assay.status === AssayStatus.CANCELED) {
        classes.push('CANCELED');
      }

      // ALERT
      if (this.assay.status === 'TECHNICIAN_REVIEW') {
        classes.push('ALERT');
      }

      // NO_RESULT
      if (!this.assay.updatedResult?.value && this.assay.updatedResult?.noResult) {
        classes.push('NO_RESULT');
      }

      // REPEAT_REQUESTED
      if (
        (this.assay.status === 'REPEAT_REQUESTED' || this.assay.repeatRequested) &&
        !this.assay.updatedResult?.noResult &&
        !this.assay.updatedResult?.value
      ) {
        classes.push('REPEAT_REQUESTED');
      }

      // HAS_CHANGES
      if (!this.resultInput?.control.errors && this.resultInput?.control.valid && this.resultInput?.control.dirty) {
        classes.push('HAS_CHANGES');
      }

      // SAVED
      if (this.hasBeenSaved() && this.assay.status !== 'TECHNICALLY_ACCEPTED' && this.assay.status !== 'RELEASED') {
        classes.push('SAVED');
      }

      if (this.assay.status === 'OPENED' && this.assay.resultRequired === 'NON_ESSENTIAL') {
        classes.push('NON_ESSENTIAL');
      }

      // ATTENTION
      if (classes.indexOf('SAVED') < 0 && this.assay.status === 'OPENED') {
        classes.push('ATTENTION');
      }

      // HAS_TRANSFORMED_VALUE
      if (this.assay.result.transformedValue) {
        classes.push('HAS_TRANSFORMED_VALUE');
      }

      // TECHNICALLY_ACCEPTED
      if (this.assay.status === 'TECHNICALLY_ACCEPTED') {
        classes.push('TECHNICALLY_ACCEPTED');
      }

      // RELEASED
      if (this.assay.status === 'RELEASED') {
        classes.push('RELEASED');
      }
    }
    this.presentationClass = classes.join(' ');
  }

  private hasBeenSaved(): boolean {
    const savedAssays = this.assayWrapperService.getSavedAssays();
    if (!savedAssays) {
      return false;
    } else {
      return savedAssays.find((savedAssay) => savedAssay.testCode === this.assay.testCode) !== undefined;
    }
  }

  setShowClinicalReference(): void {
    const assay = this.assay;
    if (!assay) {
      this.showClinicalReference = false;
    } else {
      this.showClinicalReference =
        (assay.clinicalReference &&
          assay?.status !== 'OPENED' &&
          assay?.status !== 'REPEAT_REQUESTED' &&
          !assay?.updatedResult?.noResult &&
          !assay?.updatedResult?.emptyResult &&
          !assay?.repeatRequested) ||
        (!assay?.updatedResult?.noResult &&
          !assay?.updatedResult?.emptyResult &&
          this.resultInput?.control?.dirty &&
          !assay?.repeatRequested &&
          assay.resultDefinition?.valueType !== 'FREE_TEXT');
    }
  }

  // Set card presentation based on result and status values,
  // and update presentation on user actions such as changing a result
  // and/or saying an assay has "No Result"
  setCardPresentation() {
    setTimeout(() => {
      if (this.assay) {
        this.getCardWrapperPresentation();
        this.setShowClinicalReference();
      }
    }, 0);
  }

  markAsTouchedDirty() {
    setTimeout(() => {
      this.setCardPresentation();
    }, 0);
  }

  markAsPristineUntouched() {
    setTimeout(() => {
      this.resultInput?.control.markAsPristine();
      this.resultInput?.control.markAsUntouched();
      this.setCardPresentation();
    }, 0);
  }

  handleClick($event) {
    $event.preventDefault();
    this.selectResultInput();
  }

  handleDoubleClick($event) {
    if (this.assay.updatedResult?.value && this.assay.canModify) {
      this.editMode = !this.editMode;
    }
  }

  selectResultInput() {
    if (this.appStateService.accession?.runMustBePicked) {
      return;
    }

    setTimeout(() => {
      if (this.resultInput) {
        this.resultInput.focusInput();
      } else if (this.displayValueLink) {
        this.displayValueLink.nativeElement.focus();
      }
    }, 0);
  }

  handleFocusIn() {
    if (
      !this.appStateService.currentAssay ||
      (this.appStateService.currentAssay?.testCode !== this.assay.testCode &&
        this.assayWrapper.nativeElement.contains(document.activeElement))
    ) {
      this.select();
    }
  }

  focusNext($event) {
    this.valueChange.emit($event);
  }

  select() {
    this.appStateService.currentAssay = this.assay;
  }

  get selected(): boolean {
    return this.appStateService.currentAssay?.testCode === this.assay?.testCode;
  }
}
