import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Panel } from '../panel.interface';

@Component({
  selector: 'app-panel-accept',
  templateUrl: './panel-accept.component.html',
  styleUrls: ['./panel-accept.component.scss'],
})
export class PanelAcceptComponent {
  @Input() panels: Panel[];
  @Output() updated = new EventEmitter<void>();
  @ViewChildren('acceptPanelSelector') acceptPanelSelectors: QueryList<ElementRef>;

  togglePanelAccepted(panel: Panel) {
    panel.accept = !panel.accept;
    this.updated.emit();
  }

  focusFirstPanel() {
    if (this.acceptPanelSelectors.length) {
      this.acceptPanelSelectors.first.nativeElement.focus();
    }
  }
}
