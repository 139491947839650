import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appNumericValueValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NumericValueValidatorDirective,
      multi: true,
    },
  ],
})
export class NumericValueValidatorDirective implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    let val = control.value;
    if (val !== '') {
      val = this.toNormalizedNumericValue(val);
    }
    const errors = control.errors ? control.errors : {};

    if (val === null && control.dirty) {
      errors.numericError = true;
    }
    return errors;
  }

  toNormalizedNumericValue(value: string): string {
    const [, inequality, whole, separator, fractional] = /^([<>])?(\d{0,1000})([,.])?(\d{0,1000})$/.exec(value) || [
      null,
      null,
      null,
      null,
      null,
    ];
    if (!whole && !fractional) {
      return null;
    }
    const untilNonZero = (str, character) => str + (str.length > 0 || character !== '0' ? character : '');
    const removeLeadingZeros = (str) => Array.prototype.reduce.call(str, untilNonZero, '');
    return (
      (inequality ? inequality : '') + (removeLeadingZeros(whole) || '0') + (fractional ? separator + fractional : '')
    );
  }
}
